const czechStrings = {
YourShipmentHasBeenDelivered: 'Vaše zásilka byla doručena', 
DeliveredBy: 'Doručovatel', 
RateYourExperience: 'Ohodnoťte svou zkušenost', 
ThankYouForYourValuableFeedback: 'Děkujeme vám za vaše cenné hodnocení', 
YourFeedbackWillContinuallyHelpUsImproveOurServices: 'Vaše hodnocení nám neustále pomáhá ve vylepšování našich služeb', 
DeliveryFailed: 'Doručení se nezdařilo', 
AddDeliveryPreferences: 'Zadat preference pro doručení', 
YourDeliveryPreferenceAreSavedSuccessFully: 'Vaše doručovací preference byly úspěšně uloženyKompletně', 
WeAreUnableToTrackYourShipmentRightNow: 'Nyní nedokážeme sledovat vaši zásilku', 
PleaseUpdateLocationInsideCircle: 'Aktualizujte místo uprostřed kruhu', 
Ok: 'V pořádku', 
Update: 'Aktualizovat', 
PickCurrentLocation: 'Vyberte aktuální místo', 
SearchFor: 'Hledat', 
ThisSchedulingLinkHasExpired: 'Tento odkaz na plánování vypršel', 
WeWillShareANewLinkWithYouShortly: 'Zakrátko vám zašleme nový odkaz', 
UhHo: 'Ach!', 
NeedHelp: 'POTŘEBUJETE POMOC?', 
CallSupport: 'Zavolat podporu', 
EmailSupport: 'Zaslat e-mail na podporu', 
DeliveryAt: 'Doručení v', 
ContactNo: 'Kontaktní č.', 
TrackOnMap: 'Sledovat na mapě', 
MessageToExecutive: 'Zpráva vedení', 
Cancel: 'Zrušit', 
Send: 'Odeslat', 
Executive: 'Vedení', 
Comments: 'Poznámky (volitelné)', 
SubmitFeedback: 'ODESLAT HODNOCENÍ', 
TrackingNo: 'Č. sledování zásilky', 
Details: 'Podrobnosti', 
Characters: 'Znaky', 
Updating: 'probíhá aktualizace...', 
OopsSomethingWentWrongPleaseTryAfterSomeTime: 'Ups! Něco se pokazilo, zkuste to znovu za chvíli', 
NotListedAboveSendACustomMessage: 'Pokud není uvedeno výše, zašlete vlastní zprávu', 
ChangeDeliveryLocation: 'Změnit místo doručení', 
AttemptedBy: 'Pokus o doručení provedl', 
DeliveredTo: 'Doručeno komu', 
SendMessage: 'ODESLAT ZPRÁVU', 
VIEW_DETAILS: 'Zobrazit podrobnosti', 
SAVE_LOCATION: 'ULOŽIT MÍSTO', 
LOCATION: 'místo', 
SEARCH_ADDRESS: 'Hledat adresu', 
OpenHours: 'Otevírací doba', 
Note: 'Poznámka', 
Address: 'Adresa', 
Nearest: 'Nejbližší', 
ContactNumber: 'Kontaktní číslo', 
FAQS: 'Často kladené otázky', 
HowManyTimesCanIChangeMyPickUpPoint: 'Kolikrát mohu změnit výdejní místo?', 
YouCanChangeItOnly: 'Můžete je změnit pouze', 
GoBack: 'Zpět', 
SetAsPickupStore: 'Nastavit jako výdejní místo', 
MapView: 'Zobrazit mapu', 
SearchForPickupPointNearYou: 'Vyhledat výdejní místo poblíž vás', 
Arriving: 'Na cestě k vám', 
LiveTrackingWillStart: 'Spustí se sledování v reálném čase', 
DriverTrackerWillGetActivatedOnceTheyAreOnWay: 'Sledování řidiče se zapne, jakmile vyjede', 
TrackerTimeline: 'Časová osa sledování', 
PM: 'Odpoledne', 
AM: 'Dopoledne', 
TodayAt: 'dnes v', 
TomorrowAt: 'zítra v', 
Today: 'Dnes', 
Tomorrow: 'Zítra', 
On: 'v', 
In: 'v', 
Soon: 'brzy', 
Min: 'min', 
Mins: 'min', 
Items: 'POLOŽKY', 
ViewDetails: 'Zobrazit podrobnosti', 
DeliveryInformation: 'Doručovací údaje', 
TrackingHistory: 'Historie sledování', 
StayConnected: 'Zůstat připojen', 
YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'V posledních několika minutách jste se již pokusili uskutečnit platbu. Chcete zrušit předchozí transakce', 
No: 'NE', 
yes: 'ANO', 
YourPackageWillBeDeliveredBy: 'Vaši zásilku doručí', 
OrderDetails: 'Podrobnosti objednávky', 
OrderInformation: 'Údaje o objednávce', 
Thankyou: 'Děkujeme', 
LastAttempted: 'Proveden poslední pokus', 
DeliveryBy: 'Doručil', 
MsgToFePageHeader: 'Odeslat pokyny pro doručení', 
MsgToFeSupportingText: 'Co byste chtěli sdělit řidiči?', 
AddYourMsgHere: 'Sem zadejte svou zprávu', 
InstructionsSentSuccessfully: 'Pokyny úspěšně odeslány', 
SomethingWentWrongPlsTryAgain: 'Něco se pokazilo, zkuste to znovu.', 
times: 'časy', 
TrackMovementInRealTime: 'Sledovat pohyb v reálném čase', 
SetAsPickupStore: 'Nastavit jako výdejní místo', 
MapView: 'Zobrazit mapu', 
DeliveredAt: 'Doručeno v', 
LastAttempted: 'Proveden poslední pokus', 
Tomorrow: 'Zítra',
WriteYourFeedbackOptional:"Napište svůj názor (volitelné)", 
ReturnOrder: "Return Order",
TheLinkYouAreTryingToOpenHasExpired: "Platnost odkazu, který se pokoušíte otevřít, vypršela",
SelectAnAvailableTimeSlot:"Select an available time slot",
January:"JANUARY",
February:"FEBRUARY",
March :"MARCH",
April:"APRIL",
May:"MAY",
June:"JUNE",
July:"JULY",
August:"AUGUST",
September:"SEPTEMBER",
October:"OCTOBER",
November:"NOVEMBER",
December:"DECEMBER",
Monday:"MONDAY",
Tuesday:"TUESDAY",
Wednesday:"WEDNESDAY",
Thursday:"THURSDAY",
Friday:"FRIDAY",
Saturday:"SATURDAY",
Sunday:"SUNDAY",
ViewTimings: "View Timings",
HideTimings: "Hide Timings",
PleaseAnswerAllMandatoryQuestions:"Please answer all mandatory questions",
HelpLineLink: 'Helpline Link',
ShipmentOf: "Zásilka%z",
OtherShipments: "Ostatní Zásilky",
OrderInformation:"Order Information",
OR: "nebo",
TrackShipment:"Sledovat zásilku",
TrackingDescription:"Zadejte níže uvedené údaje, abyste získali podrobnosti o vaší zásilce",
Continue:"Pokračovat",
InvalidCredentials:"Neplatné přihlašovací údaje. Zkuste to prosím znovu", 
ITEM: 'položka',
ITEMS: 'položka(y)',
OrderNo:"Číslo objednávky",
CurrentStatus:"Aktuální stav",
PickupTime:"Čas vyzvednutí",
StoreLocation:"Store Location",
LeaveBy:"Leave By",
toReachByScheduledTime:"to reach by scheduled time",
ShareThisLink:"Share this Link",
ETAtime:"ETA time",
ParkingInstructions:"Parking instructions",
PR1_heading:"Find a Spot",
PR2_heading:"Park Safely",
PR3_heading:"Secure and Exit",
PR1_description:"Look for a marked parking space; ensure it's not restricted or reserved.",
PR2_description:" Align your vehicle within the lines and leave enough space for others.",
PR3_description:"Turn off the engine, engage the handbrake, lock the car, and take your belongings.",
willBringYourOrderShortly:"will bring your order shortly.",
PODImage:"POD Image",
CopiedtoClipboard:"Copied to Clipboard",
No_order_found:"No order found!",

}
export default czechStrings;
const spanishStrings={
YourShipmentHasBeenDelivered:'Su envío se ha entregado',
DeliveredBy:'Entregado por',
RateYourExperience:'Califique su experiencia',
ThankYouForYourValuableFeedback:'Gracias por sus valiosos comentarios',
YourFeedbackWillContinuallyHelpUsImproveOurServices:'Sus comentarios nos ayudan continuamente a mejorar nuestros servicios',
DeliveryFailed:'Error en le entrega',
AddDeliveryPreferences:'Añadir preferencia de entrega',
YourDeliveryPreferenceAreSavedSuccessFully:'Su preferencia de entrega se ha guardado correctamente',
WeAreUnableToTrackYourShipmentRightNow:'No podemos realizar el seguimiento de su envío ahora mismo',
PleaseUpdateLocationInsideCircle:'Actualice la ubicación dentro del círculo',
Ok:'De acuerdo',
Update:'Actualizar',
PickCurrentLocation:'Elegir ubicación actual',
SearchFor:'Buscar',
ThisSchedulingLinkHasExpired:'El enlace de esta programación ha caducado',
WeWillShareANewLinkWithYouShortly:'En breve compartiremos un nuevo enlace con usted',
UhHo:'Oh, vaya!',
NeedHelp:'¿Necesita ayuda?',
CallSupport:'Asistencia por telèfono',
EmailSupport:'Asistencia por correo electrónico',
DeliveryAt:'Entrega en',
DeliveredAt: 'Entregado en',
ContactNo:'Número de contacto',
TrackOnMap:'Mapa de seguimiento',
MessageToExecutive:'Mensaje al ejecutivo',
Cancel:'Cancelar',
Send:'Enviar',
Executive:'Ejecutivo',
Comments:'Comentarios (opcional)',
SubmitFeedback:'ENVIAR COMENTARIO',
TrackingNo:'Número de seguimiento',
Details:'Detalles',
Characters:'Caracteres',
Updating:'actualizando…',
OopsSomethingWentWrongPleaseTryAfterSomeTime:'¡Ups! Algo ha ido mal, inténtelo pasado un rato',
NotListedAboveSendACustomMessage:'No aparece en la lista, envíe un mensaje personalizado',
ChangeDeliveryLocation:'Cambiar el lugar de entrega',
AttemptedBy:'Intentado por',
DeliveredTo:'Entregado a',
SendMessage:'ENVIAR MENSAJE',
VIEW_DETAILS:'Ver detalles',
SAVE_LOCATION:'GUARDAR UBICACIÓN',
LOCATION:'ubicación',
SEARCH_ADDRESS:'Buscar dirección',
OpenHours:'Horario de apertura',
Note:'Nota',
Address:'Dirección',
Nearest: 'Más cercano',
ContactNumber:'Número de contacto',
FAQS:'Preguntas frecuentes',
HowManyTimesCanIChangeMyPickUpPoint: '¿Cuántas veces puedo cambiar mi punto de recogida?',
YouCanChangeItOnly: 'Solo puede cambiarlo',
GoBack:'Volver',
SetAsPickupStore: 'Fijar como tienda de recogida',
MapView: 'Vista de mapa',
SearchForPickupPointNearYou:'Establecer como punto de recogida',
Arriving:'Llega',
LiveTrackingWillStart:'Se iniciará el seguimiento en vivo',
DriverTrackerWillGetActivatedOnceTheyAreOnWay:  'El rastreador del conductor se activará cuando esté en camino',
TrackerTimeline:'Cronograma de seguimiento',
PM:'Tarde',
AM:'Antes del mediodía',
TodayAt:'hoy a las',
TomorrowAt:'mañana a las',
Today:'Hoy',
Tomorrow:'Mañana',
On:'en',
In:'en',
Soon:'pronto',
Min:'min',
Mins:'mins',
Items:'ARTÍCULOS',
ViewDetails: 'Ver detalles',
DeliveryInformation:'Información de entrega',
TrackingHistory:'Historial de seguimiento',
StayConnected:'Manténgase conectado',
YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'Ya ha intentado realizar un pago en los últimos minutos. ¿Desea cancelar las transacciones anteriores?',
No: 'NO',
yes:'SÍ',
YourPackageWillBeDeliveredBy : 'Su paquete será entregado por',
OrderDetails: 'Detalles del pedido',
DeliveryBy:'Entregar antes del',
Pickuppointisupdated:'El punto de recogida se actualiza',
MsgToFePageHeader:'Enviar instrucciones de entrega',
MsgToFeSupportingText:'¿Qué quiere decirle al conductor?',
AddYourMsgHere:'Añada su mensaje aquí',
InstructionsSentSuccessfully:'Instrucciones enviadas satisfactoriamente',
SomethingWentWrongPlsTryAgain:'Se produjo un error, por favor vuelva a intentarlo.',
times:"veces",
TrackMovementInRealTime:"Seguir movimiento en tiempo real",
LastAttempted: 'Último intento',
Tomorrow:'Mañana',
Submit: 'Enviar',
WriteYourFeedbackOptional:'Escribir sus comentarios (opcional)',
OrderInformation:'Información sobre el pedido',
TrackingTimeline:'Cronograma de seguimiento',
Hours:"hours",
Hour:"hour",
WriteYourFeedbackOptional:"Escribe tus comentarios (Opcional)",
ReturnOrder: "Return Order",
TheLinkYouAreTryingToOpenHasExpired: "El enlace que intenta abrir ha caducado",
SelectAnAvailableTimeSlot:"Selecciona una franja horaria disponible",
January:"Enero",
February:"Febrero",
March :"Marzo",
April:"Abril",
May:"Mayo",
June:"Junio",
July:"Julio",
August:"Agosto",
September:"Septiembre",
October:"Octubre",
November:"Noviembre",
December:"Diciembre",
Monday:"Lun",
Tuesday:"Mar",
Wednesday:"Casarse",
Thursday:"Jue",
Friday:"Vie",
Saturday:"Se sentó",
Sunday:"Sol",
ViewTimings: "View Timings",
HideTimings: "Hide Timings",
PleaseAnswerAllMandatoryQuestions:"Please answer all mandatory questions",
HelpLineLink: 'Helpline Link',
ShipmentOf: "Envío%de",
OtherShipments: "Otros Envíos",
OrderInformation:"Order Information",
OR:"o",
TrackShipment:"Rastrea tu Envio",
TrackingDescription:"Introduce la información de tu envio para obtener detalles",
Continue:"Continuar",
InvalidCredentials:"Credenciales inválidas. Por favor, inténtelo de nuevo",
ITEM: 'artículo',
ITEMS: 'artículo(s)',
OrderNo:"N.º de pedido",
CurrentStatus:"Estado actual",
PickupTime:"Hora de recogida",
StoreLocation:"Store Location",
LeaveBy:"Leave By",
toReachByScheduledTime:"to reach by scheduled time",
ShareThisLink:"Share this Link",
ETAtime:"ETA time",
ParkingInstructions:"Parking instructions",
PR1_heading:"Find a Spot",
PR2_heading:"Park Safely",
PR3_heading:"Secure and Exit",
PR1_description:"Look for a marked parking space; ensure it's not restricted or reserved.",
PR2_description:" Align your vehicle within the lines and leave enough space for others.",
PR3_description:"Turn off the engine, engage the handbrake, lock the car, and take your belongings.",
willBringYourOrderShortly:"will bring your order shortly.",
PODImage:"POD Image",
CopiedtoClipboard:"Copied to Clipboard",
No_order_found:"¡No se encontró ningún pedido!",
}
export default spanishStrings;
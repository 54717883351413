
import { createGlobalStyle } from 'styled-components';

import { containerConstantsService } from './Utils/containerConstants/index';
const languageDirection = window.sessionStorage.getItem('languageDirection');
var isEmbed= window.location.href.indexOf('&embed=true')!=-1;
const cssjanus = require('cssjanus');
const ltr = `

:root {
  --primary-bg-color: #0460a9;
  --secondary-bg-color: #f0f8ff;
  --primary-text-color: #ffffff;
  --secondary-text-color: #0460a9;
}

html,
body {
  margin: 0px auto;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif !important;
  direction: ltr !important;
  // background-color: #f1f1f1;
  position:relative;
}


#root,#root>div {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
 
.context-menu-submenu .ant-menu-submenu-title {
  padding: 0px;
  margin: 0px;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0px;
}

td {
  word-break: inherit;
}

p {
  margin-top: 0;
  margin-bottom: 0px;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
  padding: 0;
}
b,
strong {
  font-weight: bolder;
}
.text-underline {
    text-decoration: underline;
}
.no-underline {
  text-decoration: none;
}

.m0 {margin: 0;}
.mb0 {margin-bottom: 0;}
.mt0 {margin-top: 0;}
.ml0 {margin-left: 0;}
.mr0 {margin-right: 0;}

.mla {margin-left: auto;}
.mra {margin-right: auto;}
.ma {margin: auto}

.p0 {padding: 0;}
.pb0 {padding-bottom: 0;}
.pt0 {padding-top: 0;}
.pl0 {padding-left: 0 !important;}
.pr0 {padding-right: 0;}

.m5 {margin: 5px;}
.mb5 {margin-bottom: 5px;}
.mt5 {margin-top: 5px;}
.ml5 {margin-left: 5px;}
.mr5 {margin-right: 5px;}

.m8 {margin: 8px;}
.mb8 {margin-bottom: 5px;}
.mt8 {margin-top: 8px;}
.ml8 {margin-left: 8px;}
.mr8 {margin-right: 8px;}

.p5 {padding: 5px;}
.pb5 {padding-bottom: 5px;}
.pt5 {padding-top: 5px;}
.pl5 {padding-left: 5px;}
.pr5 {padding-right: 5px;}

.m10 {margin: 10px;}
.mb10 {margin-bottom: 10px;}
.mt10 {margin-top: 10px;}
.ml10 {margin-left: 10px;}
.mr10 {margin-right: 10px;}

.p10 {padding: 10px;}
.pt10 {padding-top: 10px;}
.pb10 {padding-bottom: 10px;}
.pl10 {padding-left: 10px;}
.pr10 {padding-right: 10px;}

.pt12 {padding-top: 12px;}
.pl12 {padding-left: 12px;}


.m15 {margin: 15px;}
.mb15 {margin-bottom: 15px;}
.mt15 {margin-top: 15px;}
.ml15 {margin-left: 15px;}
.mr15 {margin-right: 15px;}

.p15 {padding: 15px;}
.px15 {padding: 0px 15px !important;}
.py15 {padding: 15px 0px !important;}
.pd20{padding:20px !important;}
.px20{padding: 0px 20px !important;}
.pb15 {padding-bottom: 15px;}
.pt15 {padding-top: 15px;}
.pl15 {padding-left: 15px;}
.pr15 {padding-right: 15px;}
.pt20 {padding-top: 20px;}
.pt25 {padding-top: 25px;}

.m20 {margin: 20px;}
.mb20 {margin-bottom: 20px;}
.mt20 {margin-top: 20px;}
.mx20{margin: 0px 20px !important;}
.mt-20{margin-top:20%;}
.ml20 {margin-left: 20px;}
.mr20 {margin-right: 20px;}

.mr24{margin-right:24px;}

.m25 {margin: 25px;}
.mb25 {margin-bottom: 25px;}
.mt25 {margin-top: 25px;}
.ml25 {margin-left: 25px;}
.mr25 {margin-right: 25px;}

.pr24{padding-right:24px}

.p25 {padding: 25px;}
.pb25 {padding-bottom: 25px;}
.pb20 {padding-bottom: 20px;}
.p20{padding:20px;}
.z10500 {z-index:10500 !important;}
.pt25 {padding-top: 25px;}
.pl25 {padding-left: 25px;}
.pr25 {padding-right: 25px;}
.br3px {border-radius: 3px;}

.m30 {margin: 30px;}
.mb30 {margin-bottom: 30px;}
.mt30 {margin-top: 30px;}
.ml30 {margin-left: 30px;}
.mr30 {margin-right: 30px;}

.p30 {padding: 30px;}
.pb30 {padding-bottom: 30px;}
.pt30 {padding-top: 30px;}
.pl20 {padding-left: 20px;}
.pl30 {padding-left: 30px;}
.pr30 {padding-right: 30px;}

.m50 {margin: 50px;}
.mb50 {margin-bottom: 50px;}
.mt50 {margin-top: 50px;}
.ml50 {margin-left: 50px;}
.mr50 {margin-right: 50px;}

.p50 {padding: 50px;}
.pb50 {padding-bottom: 50px;}
.pt50 {padding-top: 50px;}
.pl50 {padding-left: 50px;}
.pr50 {padding-right: 50px;}
/*******Float left and Right*********/
.fl-right{float: right;}
.fl-left{float:left;}

/* Padding vertical */
.pv3 {padding-top: 3px; padding-bottom: 3px;}
.pv5 {padding-top: 5px; padding-bottom: 5px;}
.pv10 {padding-top: 10px; padding-bottom: 10px;}
.pv15 {padding-top: 15px; padding-bottom: 15px;}
.pv20 {padding-top: 20px; padding-bottom: 20px;}
.pv25 {padding-top: 25px; padding-bottom: 25px;}
.pv30 {padding-top: 30px; padding-bottom: 30px;}

/* Padding horizontal */
.ph3 {padding-left: 3px; padding-right: 3px;}
.ph5 {padding-left: 5px; padding-right: 5px;}
.ph10 {padding-left: 10px; padding-right: 10px;}
.ph15 {padding-left: 15px; padding-right: 15px;}
.ph20 {padding-left: 20px; padding-right: 20px;}
.ph25 {padding-left: 25px; padding-right: 25px;}
.ph30 {padding-left: 30px; padding-right: 30px;}

/* margin vertical */
.mv3 {margin-top: 3px; margin-bottom: 3px;}
.mv5 {margin-top: 5px; margin-bottom: 5px;}
.mv10 {margin-top: 10px; margin-bottom: 10px;}
.mv15 {margin-top: 15px; margin-bottom: 15px;}
.mv20 {margin-top: 20px; margin-bottom: 20px;}
.mv25 {margin-top: 25px; margin-bottom: 25px;}
.mv30 {margin-top: 30px; margin-bottom: 30px;}

/* margin horizontal */
.mh3 {margin-left: 3px; margin-right: 3px;}
.mh5 {margin-left: 5px; margin-right: 5px;}
.mh10 {margin-left: 10px; margin-right: 10px;}
.mh15 {margin-left: 15px; margin-right: 15px;}
.mh20 {margin-left: 20px; margin-right: 20px;}
.mh25 {margin-left: 25px; margin-right: 25px;}
.mh30 {margin-left: 30px; margin-right: 30px;}

.bold {font-weight: 700;}
.semi-bold {font-weight: 500;}
.fw500 {font-weight: 500;}
.fw300 {font-weight: 300;}
.fw400 {font-weight: 400;}
.fw500 {font-weight: 500;}
.italic {font-style: italic;}

.flex {display: flex;}
.flex1 {flex: 1}
.flex-wrap {flex-wrap: wrap}
.inline-flex {display: inline-flex;}
.flex-row {flex-direction: row;}
.flex-column {flex-direction: column;}

.align-center {align-items: center;}
.align-start {align-items: flex-start;}
.align-end {align-items: flex-end;}
.align-baseline {align-items: baseline;}

.align-s-center {align-self: center;}
.align-s-start {align-self: flex-start;}
.align-s-end {align-self: flex-end;}
.align-s-baseline {align-self: baseline;}

.justify-center {justify-content: center;}
.justify-start {justify-content: flex-start;}
.justify-end {justify-content: flex-end;}
.justify-between {justify-content: space-between;}
.justify-around {justify-content: space-around;}

.bg-white {background: white;}
.bg-extra-light {background: #F0F0F0;}
.bg-none {background: none;}
.bg-info {background: #3ba5ff;}
.bg-primary {background: #18BF9A;}
.bg-success {background: #83d26f;}
.bg-danger {background: #FF3B30;}
.bg-notfound {background: #FFE9E8;}
.bg-found {background: #E8FFEF;}

.text-white {color: white;}
.text-black {color: #000000;}
.text-danger {color: #ff3b3b;}
.text-info {color: #3ba5ff;}
.text-success {color: #1ec10a;}
.text-warning {color: #ffae18;}
.text-light-gray {color: #8c8c8c;}
.text-gray {color: #929292;}
.text-dark {color: #3c3c3c;}
.text-primary {color: #18BF9A;}
.text-red {color:red;}
.text-green{color:#24A148;}
.text-grey{color:#647788}
.text-left {text-align: left;}
.text-center {text-align: center;}
.text-right {text-align: right;}

.ellipsis {text-overflow: ellipsis; width: 100%; word-wrap: break-word;}
.fs-600{font-weight:600;}

.border-none {border: none;}
.border {border: 1px solid #e8e8e8;}
.border-top {border-top: 1px solid #e8e8e8;}
.border-bottom {border-bottom: 1px solid #e8e8e8;}
.border-left {border-left: 1px solid #e8e8e8;}
.border-right {border-right: 1px solid #e8e8e8;}
.border-top-2 {border-top: 2px solid #e8e8e8;}
.border-bottom-2 {border-bottom: 2px solid #e8e8e8;}
.border-left-2 {border-left: 2px solid #e8e8e8;}
.border-right-2 {border-right: 2px solid #e8e8e8;}
.border-danger {border: 1px solid #ff3b3b}
.border-light { border-color: #F0F0F0;}


.overflow {overflow: auto;}
.overflow-x {overflow-x: auto;}
.overflow-y {overflow-y: auto;}
.overflow-hide {overflow: hidden;}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}
.width-25 {width: 25%;}
.width-50 {width: 50%;}
.width-75 {width: 75%;}
.width-100 { width: 100%;}
.w100 { width: 100px;}

.w200 { width: 200px}

.height-100 {height: 100%}

.pointer {cursor: pointer}

.view-detail {
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: right;
  }

  .view-detail svg {
    position: relative;
    top: 8px !important;
    margin-left: 5px !important;
}

.block {display: block; width: 100%}
.absolute {position: absolute}
.relative {position: relative}

.fs10 {font-size: 10px }
.fs11 {font-size: 11px }
.fs12 {font-size: 12px }
.fs13 {font-size: 13px }
.fs14 {font-size: 14px }
.fs16 {font-size: 16px }
.fs18 {font-size: 18px }
.fs20 {font-size: 20px }
.fs21 {font-size: 21px }
.fs22 {font-size: 22px }
.fs23 {font-size: 23px }
.fs24 {font-size: 24px }
.fs25 {font-size: 25px }
.fs26 {font-size: 26px }
.fs28{font-size: 28px}
.fs32 {font-size: 32px }
.fs36 {font-size: 36px }


button.react-calendar__navigation__arrow {
  font-size: 28px !important;
}

.react-calendar {
  width: 100%;
}
.react-calendar__tile--active{
  color: blue !important;
}
.react-calendar button {
  font-size: 16px;
}
.react-calendar__tile--active{
  color: blue !important;
}
.input-p-15 .MuiInputBase-input {
  padding: 15px 0 7px;
}
.react-calendar__tile--active{
  color: blue !important;
}
.leaflet-control-attribution {
  display: none;
}
leaflet-control {
  display: block;
}

.leaflet-control-container>.leaflet-bottom.leaflet-right{
  width:100%;
}
.leaflet-control-container>.leaflet-bottom>.leaflet-control-attribution.leaflet-control {
  display: block !important;
  background: transparent;
  width:100%
}

.leaflet-control-container>.leaflet-bottom>.leaflet-control-attribution.leaflet-control a{
  display: none;
}

.leaflet-control-container>.leaflet-top.leaflet-left>.leaflet-control-zoom.leaflet-bar.leaflet-control{
  height: 81px;
  background: #fff;
  box-shadow: 0em 0 0.4em 0 rgb(15 22 33 / 60%);
  border-radius: 0.5em;
  width: 40px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border: none;
}
.leaflet-control-container>.leaflet-top.leaflet-left>.leaflet-control-zoom.leaflet-bar.leaflet-control>a:hover{
  background-color:white !important;
  color:black;
}

.leaflet-control-container>.leaflet-top.leaflet-left>.leaflet-control-zoom.leaflet-bar.leaflet-control>.leaflet-control-zoom-in {
  width: 35px;
  height: 35px;
  line-height: 35px;
  color: rgba(15, 22, 33, 0.6);
}

.leaflet-control-container>.leaflet-top.leaflet-left>.leaflet-control-zoom.leaflet-bar.leaflet-control>.leaflet-control-zoom-out {
  width: 35px;
  height: 35px;
  line-height: 35px;
  color: rgba(15, 22, 33, 0.6);
}

.successImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top:20%;
  width: 200px;
}
.successMsg{
  text-align:center;
  margin-top:30px;
  margin-left:10%;
  margin-right:10%;
}

.slot-date-blocks {
  display: inline-flex;
  margin-bottom: 15px;
  margin-left: 30px;
  position: relative;
  justify-content: center;
  margin-top: 10px;
}
.slot-date {
  position: relative;
}
.slot-date-blocks .date-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 55px;
  height: 55px;
  margin-right: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  color: #000;
}

.slot-date-blocks .date-block .day-name{
  font-size: 13px;
}

.slot-date-blocks .date-block .month-year{
  font-size: 10px;
}

.slot-date-blocks .date-block.active, .slot-date-blocks .date-block:hover,.slot-date-blocks .date-block:focus {
  border: 1px solid blue;
  color: blue;
}

.slot-nav-left,.slot-nav-right {
  position: absolute;
  top: 0px;
  padding: 15px;
}
.slot-nav-left>button,.slot-nav-right>button {
  border: 0px;
  background-color: #fff
}

.slot-nav-left>button[disabled = disabled],.slot-nav-right>button[disabled = disabled] {
  color: #d3d3d3;;
}
.slot-nav-left {
  left: -45px;
}
.slot-nav-right {
  position: absolute;
  right: -40px;
}
.slot-blocks {
  display: flex;
  flex-wrap: wrap;
}
.slot-blocks>div{
  margin-right: 10px;
  margin-bottom: 5px;
  border: 1px solid #d3d3d3;
  color: #000;
  width: 80px;
  font-size: 11px;
  text-align: center;
  padding: 10px 0;
}
.slot-blocks>div.active, .slot-blocks>div:hover,.slot-blocks>div:focus {
  border: 1px solid blue;
  cursor: pointer;
  color: blue;
}
.slot-blocks>div.disabled {
  border: 1px solid #d3d3d3;
  cursor: pointer;
  color: #d3d3d3;
}

/* .react-calendar__month-view__days__day--weekend{
  color: black !important
} */
.tileClassName {
  color: black !important
}


.leaflet-pane {
  z-index: 0 !important;
}
.ci-timeline--animate{
  margin-left:60px;
}

.MuiDrawer-paperAnchorBottom{
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.MuiButton-label{
  text-transform:none !important
}
.leaflet-control-container{
  display: block !important
}



/*——————————————
ci-timeline CSS
———————————————*/
/* Base */
.timeline {
  padding: 0;
  margin: 0;
  font-size: 12px;
  margin-top: 20px;
}

.timeline li {
  position: relative;
  padding-left: 25px;
  padding-right: 15px;
}

.timeline li .right-ci-timeline p::before{
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  border: 1px solid #979797;
  background-color: #FFFFFF;
  box-sizing: border-box;
  border-radius: 50%;
  left: 0px;
  z-index: 1;
}
.timeline li:first-child .right-ci-timeline p::before{
  border: 1px solid #18BF9A;
  background-color: #18BF9A;
}

.timeline li:first-child .right-ci-timeline::after{
  position: absolute;
  content: "";
  height: 100%;
  width: 4px;
  background-color: #18BF9A;
  top: 0;
  left: 4px;
}

.timeline li .right-ci-timeline::after{
  position: absolute;
  content: "";
  height: 100%;
  width: 4px;
  background-color: #EAEAEA;
  top: -22px;
  left: 4px;
}

.timeline li:last-child .right-ci-timeline::after{
  display: none;
}



/* field-executive marker css on live-tracking*/

.sonar-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding:10px
}

/* The circle */
.sonar-emitter {
  position: relative;
  margin: 0 auto;
  width: 275px;
  height: 275px;
  border-radius: 9999px;
  background-color: HSL(45,100%,50%);
}

.outer-circle {
   margin-top: 5px;
   background: #50a758;
   border-radius: 50%;
   height: 16px;
   width: 16px;
   position: relative;
   border:1.2px solid white;
   /*
    Child elements with absolute positioning will be
    positioned relative to this div
   */
 }
 .inner-circle {
   position: absolute;
   background: white;
   border-radius: 50%;
   height: 4px;
   width: 4px;
   /*
    Put top edge and left edge in the center
   */
   top: 50%;
   left: 50%;
   margin: -2px 0px 0px -2px;
   /*
    Offset the position correctly with
    minus half of the width and minus half of the height
   */
 }


.leaflet-polyline-styling{
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1.5s linear infinite;
}

@keyframes dash { 
  to {
    stroke-dashoffset: 0;
  }
}

/* the 'wave', same shape and size as its parent */
.sonar-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  background-color: #59965b;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
}

/*
  Animate!
  NOTE: add browser prefixes where needed.
*/
.sonar-wave {
  animation: sonarWave 2s linear infinite;
}

@keyframes sonarWave {
  from {
    opacity: 0.4;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
}

.leaflet-div-icon {
  background: none !important;
  border: none !important;
}
.feeback-screen-content .cust-feedback .reason-div .MuiChip-root{
  background-color: #fff;
  padding: 8px 12px !important;
  margin: 12px 12px 0 0px;
  border: 1.5px solid #DDDFE2;
  border-radius: 8px;
  color: #727272;
  font-weight: 400;
  height: auto;
}
.feeback-screen-content .feedbackScreen{
  margin-left:15px;
}
.feedbackScreen .order_status_box i{
  font-size: 24px;
  margin-right: 8px;
}
.feeback-screen-content .comments-optional textarea{
  font-size: 14px;
  min-height: 55px;
}
.feeback-screen-content .comments-optional .Mui-focused textarea{
  border: 1px solid #0460A9 !important;
}
.feeback-screen-content  .comments-optional label{
  font-size: 14px;
  z-index: 1;
  padding: 0px 10px;
  background: #fff;
  margin-left: 5px;
}
.feeback-screen-content  .comments-optional .MuiFormLabel-root.Mui-focused{
  color: #0460A9;
}
.feeback-screen-content  .comments-optional .MuiInput-underline:after, .feeback-screen-content  .comments-optional .MuiInput-underline:before{
  display:none;
}
.feeback-screen-content  .comments-optional label + .MuiInput-formControl{
  margin-top:0px;
}
.feeback-screen-content .comments-optional .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #0460A9 important;
  border-width: 1px !important;
}

.feedbackScreen .star-rating-1, .feedbackScreen .star-rating-2, .feedbackScreen .star-rating-3, .feedbackScreen .star-rating-4, .feedbackScreen .star-rating-5{
font-size:50px !important;
}

.star-rating{
    border: 0;
  	display: flex;
  	flex-direction: row-reverse;
  	justify-content: auto;
  	text-align: center;
    width: 5em;
    // margin-bottom: 20px;
}

.feeback-screen-content .star-rating-1, .feeback-screen-content .star-rating-2, .feeback-screen-content .star-rating-3, .feeback-screen-content .star-rating-4, .feeback-screen-content .star-rating-5 {
  width: 100%;
  border: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  float:left;
}

.feedback_page .star-rating-1, .feedback_page .star-rating-2, .feedback_page .star-rating-3, .feedback_page .star-rating-4, .feedback_page .star-rating-5 {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  margin-bottom:20px;
}

.star-rating input, .star-rating-1 input, .star-rating-2 input, .star-rating-3 input, .star-rating-4 input, .star-rating-5 input{
	display: none;
}

.star-rating-1 label, .star-rating-2 label, .star-rating-3 label, .star-rating-4 label, .star-rating-5 label {
	color: #DDDFE2;
  cursor: pointer;
}

.star-rating-1 :checked ~ label, .star-rating-1 :hover ~ label  {
	color: #FF7033;
}
.star-rating-2 :checked ~ label, .star-rating-2 :hover ~ label{
	color: #FF9533;
}
.star-rating-3 :checked ~ label, .star-rating-3 :hover ~ label {
	color: #F1C21B;
}
.star-rating-4 :checked ~ label, .star-rating-4 :hover ~ label {
	color: #2FCE49 ;
}
.star-rating-5 :checked ~ label, .star-rating-5 :hover ~ label {
	color: #24A148;
}

.star-rating-1 .MuiChip-colorPrimary  {
	background-color: #FF7033 !important;
}
.star-rating-2 .MuiChip-colorPrimary  {
	background-color: #FF9533 !important;
}
.star-rating-3 .MuiChip-colorPrimary  {
	background-color: #F1C21B !important;
}
.star-rating-4 .MuiChip-colorPrimary  {
	background-color: #2FCE49 !important;
}
.star-rating-5 .MuiChip-colorPrimary  {
	background-color: #24A148 !important;
}

.star-rating :checked ~ label {
	color: rgb(255, 212, 59);
}

.star-rating :hover ~ label {
	color: rgb(250, 176, 5);
}

.star-rating label {
	color: rgb(128, 128, 128);
  cursor: pointer;
}
.ci-top .social-inner-wrapper{
  width: 920px;
  margin: auto;
}
.ci-top .social-inner-wrapper p{
 margin-bottom:0px;
}
.ci-puf .company-logo-div{
  margin-left: 45px;
}
.ci-processform fieldset legend, .map-address fieldset legend{
  ${ languageDirection == "rtl" ?  'text-align: unset; margin-left:10px;' : 'text-align: left'};
}
.ci-processform .MuiFormControl-marginNormal.width-100 .MuiInputLabel-formControl, .map-address .MuiFormControl-marginNormal.width-100 .MuiInputLabel-formControl{
  ${ languageDirection == "rtl" ?  'left:28px !important; transform-origin: top left;' : 'left:0px !important'};
}
.feedback_page .feedback-iframe{
  width:100%;
  height:360px;
  zIndex:1;
}
.feedback_page .feedback-iframe-layer{
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  height:360;
  width:100%;
  zIndex;
}
.feedback_page .feed_popup .feedback-iframe{
  height:100vh;
}
.feedback_page .feed_popup .feedback-iframe-layer{
  display:none;
}
.feedback_page .pod-heading{
    background:white; 
    padding: 14px 24px;
    font-weight: bold;
}
.tracking-no-div{
  float: left;
}
.group {	
  height: 60px;
  width: 360px;
}


.shipment {	
  box-shadow: 0px 0.6px 2.8px 0px #05223D0F;
  border: 1px solid #F3F3F3;
  color: #212121;	
  width:100% !important;
  font-weight: 500;	
  line-height: 16px;
  font-family: Roboto;
  vertical-align: middle;
  
  
  //  padding-left: 15px;
  //  padding-right: 10px;
  //  padding-top: 10px;
  //  border-bottom: 2px solid #ede9e9;
  }

  
.shipment-title {	
    color: #727272;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    background-color: #F8F8F8;
    width: 100% !important;
    display: block;
    padding: 10px 15px;
}



.track {
  box-sizing: border-box;
  color: #4A90E2;
  border: 1px solid #4A90E2;
  border-radius: 2px;
  background-color: #FFFFFF;
  float: right;
  margin-right: 10px;
  text-align: center;
  padding: 10px 8px;;
  }
.contact-left {
    width: 50% !important;
    max-width: 50% !important;
    display: inline-block;
    padding-top: 10px;
}
.track-map-right {
  width: 50% !important;
  float: right;
}

.rating-div {
    width: 100%;
    border-radius: 4px;
    background-color: rgba(255,255,255,0.08);
    box-shadow: 0 -2px 4px 0 rgba(0,0,0,0.5);
    // position: absolute;
    bottom: 0;
}

.rate-your-experience {	
  text-align: center;	
  color: #212121;	
  font-size: 18px;	
  margin-top:25px;
  line-height: 21px;
 
}

.comments-optional p {	
  color: #727272;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 10px;
  letter-spacing: 0.1px;
}
.comments-optional textarea{width: 100%; border-radius: 4px;}
.reason-div {
  padding: 7px;
}.reason-div span {
  padding: 0 !important;
  vertical-align: middle;
}

/******Shipment Styling***********/

.ship-title{float: left; width:35%}
.ship-detail-status{float: right; display: inline-block; width:55%}
.ship-detail.clearfix {
  width: 20%;
  display: inline-block;
}
.ship-block.clearfix {
  width: 80%;
  display: inline-block;
  text-align:right;
}
.ship-title span {
  font-size: 13px;
  font-weight: bold;
  vertical-align: -webkit-baseline-middle;
}
.ship-block span small {
  color: #747474;
}
.ship-block span {
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  color: #000;
}
.circle-excl {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #cccccc;
  margin: 20px auto;
  position: relative;
}
.circle-excl span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: #cccccc;
}
.scheduling-link{
    color: rgba(33, 33, 33, 0.6);
    text-align: center;
    margin-top: 30px;
    margin-left: 10%;
    margin-right: 10%;
    font-family: "Roboto Light" !important;
    font-size: 20px;
    line-height: 30px;
}
.share-link{
  text-align: center;
    margin-top: 30px;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: bold;
}

.error-message {
    color: red;
    font-size: 12px;
    text-align: center;
    margin-bottom: 15px;
}
.status_log_screen{
  height:70%;
  background-color:#fff;
  margin-bottom:10px;
  width:100%;
  }
  
  .mainDivHeadingTimeline{
    padding-bottom:10%;
  }
  .height30{height:30%;}
  .status_log{
    overflow-y:auto;
    max-height:300px;
  }
  .ellipse-chat {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .help_screen span{
    display:flex;
    align-items:center;
    font-size:18px;
    }
    .help_screen span svg{
      margin:10px;
      float:left;
    }
    .help_screen span img a{
    text-decoration:'none';
    color:'black';
    
    }
    .help_screen ul{
    padding-top: 0;
    font-size:16px
    }
    
    .help_screen > ul > .MuiListItem-root {
      padding: 8px 0;
    }

    .fl-right{
      float:right;
    }
    
    .address-detail{
      padding-left:10px;
      height:35%;
      margin-top: 15px;
    }
    .desktop-layout-screen-cta-btn {
      margin-bottom: 10px;
    }
    .desktop-layout-screen-cta-btn a {
      padding: 10px 16px;
      background: #F0F8FF;
      border-radius: 4px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #0460A9;
  }
    .overflow-y{
      overflow-y: auto;
    }
    .maxHeight-300{
      max-height: 300px;
    }
    .feedbackDiv{
      position: fixed;
      padding: 10;
      bottom: 0;
      left: 0;
      right: 0
    }
    .feeback-screen-content{
      height: calc(100% - 100px);
      overflow-y: auto
    }
    .getMapDiv{
      position: 'absolute';
      bottom: 10;
      left: 10;
      right: 10;
      padding: 10;
      background: #fff;
      border-radius:5px;
    }
    .getMapDiv span{margin-left: 20px;}
    .getMapDiv .fe-chat-circle img{margin-top: 25%;}
    .messageExecDiv .MuiOutlinedInput-input{
      height: 100px;
    }
  .customMessageDiv label{padding: 10px 0;}
  .customMessageDiv span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
      padding-top: 10px;
      line-height: 20px;
  }
  .messageArea .MuiOutlinedInput-input {
    min-height: 100px;
  }

  @font-face { 
    font-family: "Futura LT";
    src: url('./Fonts/FuturaLT.ttf' ) format('woff');
    src: url('./Fonts/FuturaLT.ttf') format('truetype'); 
  }
.help_screen ul .MuiListItem-root {
  display: flex;
  width: 100%;
}
.help_screen ul .MuiListItem-root .MuiTypography-root {
  display: flex;
  width: 100%;
  align-items: center;
}
.help_screen ul .MuiListItem-root .MuiTypography-root p{
  margin:0;
}
/************* common-styling add ****************/

.mt45{
  margin-top: 45px;
}
.m-auto{
  margin-left:auto !important;
  margin-right: auto !important;
}
.disp-block{display: block !important;}
.margin30-auto{margin:30px auto;}
.width30{
  width:30%;
}

.width80 {
  width: 80% !important;
}

/********** Custom Screen Styling *************/
.leftImgCategDiv {
  width: 60%;
  float: left;
}
.rightCatRefDiv {
  width: 30%;
  float: left;
}
.fontItalic {
  font-style: italic;
}
.no-box-shadow{
  box-shadow: none !important;
}
.CloseBtn{
  padding: 0;
  text-align: right;
  min-width: 40px;
}

.mui-label{
  transform:none !important
}


.popupCustom .leaflet-popup-content-wrapper {
    // width: 135px;
    background-color: #FFFFFF;
    box-shadow: none !important;
    position:relative !important;
    border-radius:0 !important;
    margin-bottom:16px !important
    right: 41px !important;
}
.popupCustom .leaflet-popup-content-wrapper .leaflet-popup-content{
  width: 131px !important;
  padding: 6px 1px !important;
  font-size: 11px !important;
  line-height: 8px!important;
  margin: 0 !important;
  text-align: center;
}
.leaflet-popup-close-button {
  display:none !important;
}

.popupCustom .leaflet-popup-tip-container{
  margin-bottom:30px !important;
  margin-left:5px !important;
  width: 20px !important;
  height: 15px !important;
  top: 22px !important;
  right: 55px !important;
  left: initial !important;
}

.popupCustom .leaflet-popup-tip {
  width: 12px;
  height: 12px;
}

.popupSmallCustom .leaflet-popup-content-wrapper {
  // width: 135px;
  background-color: #FFFFFF;
  box-shadow: none !important;
  position:relative !important;
  border-radius:0 !important;
  margin-bottom:16px !important
  right: 25px !important;
}
.popupSmallCustom .leaflet-popup-content-wrapper .leaflet-popup-content{
    width: 85px!important;
    padding: 6px 1px !important;
    font-size: 11px !important;
    line-height: 8px!important;
    margin: 0 !important;
    text-align: center;
}

.popupSmallCustom .leaflet-popup-tip-container{
  margin-bottom:30px !important;
  margin-left:5px !important;
  width: 20px !important;
  height: 15px !important;
  top: 22px !important;
  right: 34px !important;
  left: initial !important;
}

.re-center {
  position: absolute;
  width: 30px;
  height: 30px;
  background: white;
  z-index: 1000;
  right: 48px;
  bottom: 100px;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0px #676767;
  display:flex;
  align-items:center;
  justify-content:center;
  // padding-top: 10px;
  // cursor: pointer;
}
.ci-tracking-map-conatiner {
  width: 100%;
  padding:${isEmbed ?'0px':'32px 32px 32px'};
  position: relative;
  // background-color: #fff;
}
.widget5 > .ci-tracking-map-conatiner {
  padding :0px !important;
}
.ci-tracking-map-conatiner + .desktop-view-market-asset {
  position: relative;
  top: -32px;
}
//.fe-chat-circle{
//  margin-left:60px !important;
//}
.re-center img {
  width: 20px;
  // margin-top: 5px;
}
  
button.CloseBtn {
  min-width: 50px;
  text-align: right;
  position: relative;
  top: 4px;
  left: 9px;
}

button.CloseBtn span.MuiButton-label{
  display: block;
  left:9px;
  top:0
}

button.action-btn span.MuiButton-label{
  position: absolute;
  right: 20px;
  top: 0px;
}
.logo-back-click {
  display: flex;
  width: 100%;
}
span.text-center.width80.inner-heading-title {
  position: absolute;
  left: 48px;
}
.logo-back-click button:hover{
  background: transparent;
}
.logo-back-click .company-logo-div {
  margin-left: 0 !important;
  width: calc(50% + 101px);
}

@media screen and (max-width: 767px) {
  .logo-back-click .company-logo-div {
    width: 50%;
    max-width: calc(250px + 48px);
    margin-left: 0 !important;
  }
  .logo-back-click {
    display: flex;
    width: 100%;
  }
}

.slick-list{
  height:auto !important;
}

.background-grey.desktop{
    background:#fff !important;
    overflow: visible;
    height: 300px;
}

.desktopView .background-grey.desktop{
  background:#fff !important;
  padding:20px 20px 30px 20px;
  overflow: visible;
  height: 300px;
}
.width-90{
  width:90% !important;
}
.width-80{
  width:80% !important;
}
.carousel-maiDiv {
  background: #fff !important;
  padding: 0;
}
.desktopView .carousel-wrapper .border-top {
  top: 45px;
  width: 25%;
}
.desktopView .carousel-wrapper .border-bottom {
  top: 45px;
  width: 25%;
}
.desktopView .address-detail .contact-sec span.text-gray {
  font-size: 14px;
  color: #4C5055 !important;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  // font-weight: lighter;
}
.desktopView .address-detai p.fs14.pt5 {
  color: #29313A;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}
.desktopView .address-detail .contact-sec  span.fs12.text-gray.mt15{
  width: 178px;
  display: inline-block;
  margin-top:0 !important;
}
.desktopView .fieldExDetails p.mob {
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px;
  color: #29313A;
}
.desktopView .address-detail .contact-sec span.fs14.mb15 {
  color: #29313A;
  font-weight: 400;
  display:inline-block
}
.desktopView .address-detail .contact-left {
  width: 100% !important;
  max-width: 100% !important;
}
.desktopView .address-detail .contact-sec .MuiGrid-root.contact-left.MuiGrid-item.MuiGrid-grid-xs-6 br {
  display: none;
}
.desktopView .address-detail p.text-gray {
  font-size: 14px;
  color: #4C5055 !important;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  // font-weight: lighter;
  width:178px;
  display: inline-block;
  vertical-align:top;
}
.desktopView .desktopDeliverySec p.fs14.pt5 {
  display: inline-block;
}
.desktopView .fieldExDetails span.dliveryby{
  width: 178px;
  display: inline-block;
  font-size: 14px;
  color: #4C5055 !important;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  // font-weight: lighter;
  vertical-align:top;
}
.desktopView .fieldExDetails span.userWithNumber{
  display: inline-block;
  text-transform:capitalize;
  color: #29313A !important;
  line-height:22px;
}
.desktopDeliverySec .contact-sec.clearfix {
  margin-top: 10px;
  margin-bottom: 10px;
}
.desktopDeliverySec p.pb10.fs16.fontFamilyLight.light-grey {
  padding-bottom: 0 !important;
}
.desktopView .MuiGrid-root.bg-white.p15.MuiGrid-container {
  padding: 20px 32px 16px !important;
}
.desktopView .address-detail{
  padding: 0 !important;s
}
p.mob img {
  margin-right: 10px;
  position: relative;
  top: 4px;
  margin-top: 0 !important;
}
.desktopDeliverySec .address-detail p.fs14.pt5 {
  min-width: 178px;
  max-width: 260px;
  vertical-align: top;
  padding: 0 !important;
  word-wrap: break-word;
}
.layout-screen-customize .layout-screen-mobile-wrapper .leaflet-left{
  left: 0;
  // right:unset;
  // margin-left:15px;
}
.layout-screen-customize .social-media-links p{
  margin-bottom: 0px !important;
}
/******************Order Tarcking Collapsible Styling****************/

.event-address-details .address-detail{
  padding: 0px 10px;
}
.event-address-details .ci-con-label, .event-address-details .contact-sec .track{
  margin:0px;
}
.event-address-details .contact-sec .track{
  white-space: nowrap;
}
.event-address-details .contact-sec .contact-left{
  min-width: 100% !important;
}

div#panel1a-header, div#panel2a-header, div#panel3a-header {
  background: #F6F6F6 !important;
  margin-bottom: 10px;
}
div#panel1a-header p, div#panel2a-header p, div#panel3a-header p {
  font-family: 'Roboto', sans-serif !important;
  color: #29313A !important;
  font-size: 16px;
  font-weight: 600;
}
.feedback-custom.custom-order-tracking-header span {
  text-align: left !important;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
.bg-white.orderTrackingScreen .MuiPaper-root.MuiExpansionPanel-root.Mui-expanded.MuiExpansionPanel-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  margin: 0 !important;
  margin-top: 0 !important;
}
.bg-white.orderTrackingScreen .MuiExpansionPanel-rounded:last-child {
  box-shadow: none !important;
}
.orderTrackingScreen .orderInfo span {
  width: 100% !important;
  display: block;
  line-height: 25px;
}
.orderTrackingScreen .orderInfo{
  margin-bottom:0;
}
.orderTrackingScreen .orderInfo .p10.pl0:last-child {
  border: none !important;
}
.orderTrackingScreen .orderInfo .p10.pl0 {
  border-bottom: 1px solid #EEEEEE;
}
.orderTrackingScreen .MuiExpansionPanelDetails-root {
  padding-bottom: 7px !important;
}
.orderTrackingScreen .timeline{
  margin-bottom:0 !important;
  position:relative;
  margin-top: 0 !important;
}

span.need_help_box button {
  // font-family: 'Roboto', sans-serif !important;
  border: none;
  line-height: 18px;
  border-radius: 4px;
  right: 0px;
}
.orderTrackingScreen .timeline li:first-child .right-ci-timeline p::before{
  border: 1px solid #016490;
  background-color: #016490;
  height: 15px;
  width: 15px;
  left: 0px;
}

.orderTrackingScreen .MuiExpansionPanel-root:before {
  background: transparent;
}
.layoutPhone{
  margin-left: 12px;
  max-width: calc(100% - 60px);
}
.layoutPhone p{
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;  
  word-break: break-all;
}
.layoutPhone .feMob{
    letter-spacing: 1px;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    margin-top: 2px;
}

.fe-details {
  padding:  24px 12px;
  // border-radius: 8px;
}
.fe-details .fe-details-label{
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
}

.fe-details-label{
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
}
.fe-chats{
  float: right;
  display: flex;
  margin-right: 16px;
}

.ci-v3eta .label{
  margin-bottom: 4px;
  font-size: 14px;
  font-weight:400;
}

.live-track-map-view .re-center {
  bottom: 140px;
}

.ci-top .leaflet-left {
	left: 0;
  // right:unset;
  // margin-left:15px;
}

.desktopView .orderTrackingScreen {
  float: right;
  width: 40%;
}
.desktopView .orderInfo span {
  width: 100%;
  display: inline-block;
  font-size: 14px;
}
.desktopView .orderInfo .box{
  display:flex;
}
.desktopView .orderTrackingScreen {
  height: 350px;
  border: 2px solid #F6F6F6;
  border-radius: 4px;
  width:100%
  overflow:hidden;
  position: relative;
  top: 0px;
}
.orderTrackingScreen h4 {
  background: #F6F6F6;
  padding: 10px;
  color: #29313A;
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px;
  letter-spacing: 0.1px;
  margin-bottom: 20px;
}
.desktopView .orderTrackingScreen .bg-white{
  padding:10px 10px 0 10px;
}
 .orderTrackingScreen .timeline li:first-child .right-ci-timeline::after {
   background-color: #016490;
   top: 10px;
 }

.right-ci-timeline ::after {
    position: absolute;
    content: "";
    height: 80%;
    width: 2px;
    border-left: 2px solid #016490;
    top: 0px;
    left: 6px;
}
p.carousel-desc {
  color: #29313A;
  font-size: 14px;
  text-align: center;
  margin-bottom: 3px;
}
p.carousel-price {
  text-align: center;
  color: #808994 !important;
  font-size: 12px;
}
.desktopView .carousel-content {
  height: 60px !important;
    margin-top: 0;
    padding: 0px 15px;
}
.carousel-content {
  margin-top:10px;
}
.MuiExpansionPanel-root.Mui-expanded{
  margin:0 !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.desktopView.mt20 {
  width: 900px;
  margin: 20px auto 20px;
}
.HeaderInner.flex.width-100 {
  width: 900px;
  margin: 0 auto;
  height: 55px;
}
.social-inner-wrapper{
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  background: #ffff;
  left: 0;
  width: 100%;
  padding: 10px;
  z-index: 5;
}
.social-inner-wrapper svg.MuiSvgIcon-root.mr5 {
  margin-right: 15px;
  font-size: 15px;
}
.social-media-links {
  width:100%;
}
.social-inner-wrapper span.fs12 {
  vertical-align: super;
  position: relative;
  top:4px;
}

.social-inner-wrapper span img {
  width: 16px;
}

.social-media-links .extrainfo{
  max-width: 55%;
  text-align: right;
  position: absolute;
  right: 0px;
}
.social-media-links .extrainfo span {
  position: relative;
}
.upcomimgTimeline .timeline li:first-child .right-ci-timeline p::before {
  border: 1px dashed #E3E6E9;
  background-color: #E3E6E9;
  height: 15px;
  width: 15px;
  left: 0px;
}
.upcomimgTimeline .timeline li .right-ci-timeline p::after {
  position: absolute;
  content: "";
  height: 92%;
  width: 2px;
  border-left: 2px dashed #E3E6E9;
  top: -42px;
  left: 6px;
}


.upcomimgTimeline {
  padding-bottom: 40px;
}
.desktopView .slick-prev::before {
  content: url(https://staging.fareye.co/img/biker/staging/jtech/Back.png) !important;
}
.desktopView .slick-next::before {
  content: url(https://staging.fareye.co/img/biker/staging/jtech/Next.png) !important;
}
.desktopView .slick-prev, .desktopView .slick-next{
  top:50% !important;
}  
.desktopView .slick-initialized[dir=rtl] .slick-prev {
  left: -12px ;
}  
.desktopView .slick-initialized[dir=rtl] .slick-next{
  right: -12px ;
}
.desktopView .slick-prev{
  width: 14px;
  height: 24px;
}
.desktopView .slick-next{
  width: 14px;
  height: 24px;
}

.re-eta-widget {
  Weight: 500
  Size: 14
  Style: normal
  Character: 0
  Color: #29313A
  Horizontal: Left
  Vertical: Top
}


.pudo-save-alert .message-snack-bar .MuiSnackbarContent-root{
  background-color: black !important;
  margin-bottom:4px !important;
}
.message-snack-bar .MuiSnackbarContent-root {
  background-color:#4caf50 !important;
  margin-bottom:4px !important;
}
@media (min-width: 600px) {
   .message-snack-bar .MuiSnackbar-anchorOriginBottomCenter {
        bottom: 100px !important;
    }
}
.mylocationDiv, .viewListDiv{
  position: absolute;
    z-index: 100000;
    background: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    right: 15px;
    padding: 10px;
}
.viewListDiv{
  bottom: 32%;
}
.mylocationDiv {
  bottom: 42%;
}
.mylocationDiv svg {
  padding: 5px;
  font-size: 20px;
  color: #727272;
}
.viewListDiv svg {
  font-size: 25px;
  padding: 2px;
  color: #18BF9A;
}
.card-view {
  position: fixed;
  z-index: 100000 !IMPORTANT;
  bottom: 20px !important;
  display: block;
  width: 100%;
}



.singleDetialDiv {
  padding-left: 15px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.arrow-forward svg {
  font-size: 17px !important;
    position: relative;
    right: 10px;
}
.mapviewDiv {
  padding: 5px 20px 15px 20px;
  position: fixed;
  bottom: 0;
  box-shadow: 0 -3px 3px #ccc;
  width: 100%;
}
.shopDetailScreen .renderMapSection {
  height: 150px;
}

.shopDetailScreen-bottomDiv {
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -3px 3px #ccc;

  height: 48px;
  max-height: 48px
  padding-top: 8px;
  padding-bottom: 8px;
}
.shopDetailScreen-bottomDiv span {
  display: inline-block;
  line-height: 16px;
  text-align: center;
}
.shopDetailScreen-bottomDiv .GoBackBtn{
  float: left;
  width: calc(50% - 20px);
  margin-left: 16px;
}

.shopDetailScreen-bottomDiv .pickupStoreBtn {
  float: right;
  width: calc(50% - 20px);
  margin-right: 16px;
}
.shopDetailScreen-bottomDiv .GoBackBtn button {
  padding: 8px !important;
  height: 48px;
  border: none !important;
  border-radius: 4px;
  font-weight: 600;
  width: 100% !important;

  color: #49bd94;
  font-family: 'Roboto', sans-serif; !important;

}
.shopDetailScreen-bottomDiv .pickupStoreBtn button {
  padding: 0px 4px !important;
  height: 48px;
  border: none !important;
  border-radius: 4px;
  font-weight: 600;
  width: 100% !important;

  background: #49bd94;
  color: #fff;
  font-family: 'Roboto', sans-serif !important;
}

.shopDetailScreen .shopDetailTopSec {
  padding: 10px 16px 0 ;
}
.shopDetailScreen span.directionIcon{
  position: relative;
  top: 10px;
  float: left;
  width: 32px;
}

.shopDetailScreen span.phoneIcon, 
.shopDetailScreen span.queryBuilderIcon{
  position: relative;
  top: 10px;
  float: left;
  width: 30px;
}

.pudoShopFeedbackHeader span.directionIcon, .pudoShopFeedbackHeader span.phoneIcon, .pudoShopFeedbackHeader span.queryBuilderIcon {
  margin-right: 10px;
  position:relative;
  top:18px;
}
.pudoShopFeedbackHeader a, .pudoShopFeedbackHeader svg {
  color: #727272 !important;
  font-size:20px;
}
.pudoShopFeedbackHeader{
  padding: 10px 20px 0;
}
.shopDetailScreen {
  height: calc(100% - 65px);
  overflow-y: auto;
  overflow-x: hidden;
}

input#standard-name {
  // font-family: 'Roboto', sans-serif;
}

button.nearestBtn {
  background: #4A90E2 !important;
  color: #fff;
  padding: 3px 10px !important;
  border-radius: 4px;
  height: 24px !important;
  font-size: 12px !important;
  position: relative;
  top: 12px;
  border: none !important;
  font-family: 'Roboto', sans-serif !important;
  line-height: 10px;
  float:right;
}
.locationName{
  width:100%;
  margin-right:15px;
  word-break: break-word;
}
.arrow-forward {
  float: right;
  text-align: right;
  width: 100px;
}
.card-layout button.nearestBtn {
  top: 0 !IMPORTANT;
}
.renderMapSection #map {
  height: 150px !important;
}
.pudo-shop-map img.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive {
  height: auto !important;
}
.pudo-shop-map svg.MuiSvgIcon-root.fs24 {
  color: #727272 !important;
}
.collectPoints h4 {
  color: #212121;
  font-weight: 600 !important;
}
.collectPoints p {
  color: #727272;
  padding-top:2px;
}
.help_screen a {
  color: #212121;
  font-size: 14px;
  font-weight: 600 ;
  text-decoration: none ;
}
.help_screen a:hover{
  color: #212121 !IMPORTANT;
}
.help_screen .title {
  color: #212121 !IMPORTANT;
  font-size: 18px;
  font-weight: 600;
}
.listScreen {
  margin-bottom: 70px;
  overflow-y:scroll;
}

// .shopDetailScreen a, .shopDetailScreen svg {
//   color: #727272 !important;
//   font-size:20px;
// }
.pudo-shop-map .popupCustom .leaflet-popup-content-wrapper {
  background-color: transparent;
  box-shadow: none !important;
  position: relative !important;
  margin-bottom: 20px !important;
  width: 101px !important;
  font-family: 'Roboto', sans-serif !important;
  right:0 !important;
}
.pudo-shop-map .leaflet-popup-tip {
  background: #16bf9a;
  color: #333;
  box-shadow: none;
  border-radius: 4px !important;
}
.pudo-shop-map .leaflet-pane.leaflet-marker-pane {
  z-index: 100000000 !IMPORTANT;
}
.pudo-shop-map .leaflet-popup {
  margin-bottom: 19px;
}
.pudo-shop-map .popupCustom .leaflet-popup-content-wrapper .leaflet-popup-content {
  width: 101px !important;
  padding: 5px 2px !important;
  font-size: 12px !important;
  line-height: 15px!important;
  margin: 0 !important;
  text-align: center;
  color: #fff !important;
  background: #16bf9a;
}
.pudo-shop-map .popupCustom .leaflet-popup-tip-container {
  margin-bottom: 30px !important;
  margin-left: 5px !important;
  width: 20px !important;
  height: 15px !important;
  top: 26px !important;
  right: 40px !important;
  left: initial !important;
}
.common-grey{
  color:#727272 !important;
}
p.delayMessage {
  background: #FFFDEA !important;
  font-size: 11px;
  padding: 5px;
  border: 1px solid #EBE170;
  font-family: Roboto, sans-serif;
  color: #727272;
  border-radius: 4px;
  float: left;
  clear:both;
  font-weight:bold;
}
.faq-heading {
  clear: both;
}
.shopDetailBottomSec{
  float: left;
  clear:both;
  width:100%;
  border-bottom: 5px solid #f1f1f1;
  overflow: auto;
  padding: 16px;
}

.faq-div{
  padding: 10px 10px 10px 16px;
  overflow-wrap: break-word;
}

.slick-prev:before {
  content: '<' !important;
  color:#000 !important;
}

.slick-next:before{
  content: '>' !important;
  color:#000 !important;
}


.desktopView .vertical-timeline-element {
  margin: 0 0 !important;
  padding: 0 0 1em 0 !important;

}

.desktopView .vertical-timeline-element .pb20 {
  padding-bottom: 10px !important;
}

.desktopView .upcomimgTimeline.mb20{
  margin-bottom:0px !important;
}
.vertical-timeline-element {
  margin: 0em 0 !important;
  padding: 0px 0 15px 0;
}


/************* Timeline Component Design ******************/
.vertical-timeline-element-content {
  padding: 0 !important;
  box-shadow: none !important;
}
.vertical-timeline-element-icon {
  width: 20px !important;
  height: 20px !important;
  box-shadow: none  !important;
  background-color: rgb(0, 47, 95);
  left:0px;
}
.vertical-timeline::before {
  left: 8px !important;
  background-color: rgb(0, 47, 95) !important;
}
.vertical-timeline {
  padding: 0 !important;
}
.vertical-timeline-element-content p {
  margin: 0 !important;
}
.vertical-timeline-element-content {
  margin-left: 35px !important;
  margin-right:unset !important;
}
.upcomimgTimeline .vertical-timeline-element-icon {
   background: #ddd !important;
}
.millerknoll .vertical-timeline-element-icon {
   background: #DC3A0F !important;
}
.upcomimgTimeline .vertical-timeline::before {
  background: #ddd !important;
 }

  .millerknoll .vertical-timeline::before {
   background-color: #DC3A0F !important;
   left: 6px !important;
}

.upcomimgTimeline .right-ci-timeline p {
  margin-bottom: 25px;
}
.DateTimeDiv .time {
  color: #727272;
  font-size: 12px;
}
.DateTimeDiv {
  width: 31%;
  text-align: right;
}
.statusRemarkDiv {
  width: 69%;
}
.orderTrackingScreen .timeline li:first-child .right-ci-timeline p::before{
  border: 1px solid #016490;
  background-color: #016490;
  height: 15px;
  width: 15px;
  left: -1px;
}
.statusRemarkDiv.right-ci-timeline p {
  margin-bottom: 10px;
}
.eventScreenContent .address-detail{
  padding-left:10px;
  height:35%;
  padding-top: 15px;
  padding-bottom:50px;
  margin-top:0 !important;
  margin-bottom:5px;
}
.desktopView .vertical-timeline-element-content .statusRemarkDiv p, .desktopView .upcomimgTimeline .vertical-timeline-element-content p{
  font-size: 0.8rem!important;
}

.desktopView .vertical-timeline-element-content .statusRemarkDiv p span{
  font-size: 12px;
}
.desktopView .vertical-timeline-element-content p.date-timeline.text-black{
  font-size: 0.8rem!important;
    color: #000 !important;
}

.multipartShipmentStatus .mainDivHeadingTimeline {
  padding-bottom: 0 !important;
  padding: 7% 0;
  height: 100% !important;
  overflow: auto !important;
}



/******** Some Common Styling for Input Control Label*************/
  .MuiInputLabel-formControl {
    left: 0 !important;
    right: inherit !important;
}
.company-logo-div {
  width: 50%;
  max-width: 250px;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 38px;
  margin-top: auto !important;
  margin-bottom: auto !important;
  justify-content: start;
  margin-left: 0 !important;
}
.company-logo-img{
  max-width: 100%;
  top: 0;
  bottom: 0;
  max-height: 38px;

}
.need_help_box {
  margin-left:auto;
  margin-right: 0px;
  margin-top: auto  !important;
  margin-bottom: auto  !important;
 }
.leaflet-pane.leaflet-marker-pane img.u-turn-icon {
  width: auto !important;
}
.statusHeader{
  padding: 20px 10px;
}

.inaccurate-tracking-message {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  padding: 12px;
  position: absolute;
  z-index: 999;
  background-color: white;
  border-radius: 4px;
  top: 10%;
  margin: 0 22%;
  visibility: hidden;
}

@media only screen and (max-width:767px) {
  .inaccurate-tracking-message {
    margin: 0 4% 0 20%;
    top: 6%;
    padding: 8px;
    font-size: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}

@media only screen and (min-width: 1170px){
 
  .vertical-timeline--two-columns .vertical-timeline-element-icon {
      left: 0;
      margin-left: 0 !important;
  }
  .vertical-timeline--two-columns .vertical-timeline-element.vertical-timeline-element--right .vertical-timeline-element-content, .vertical-timeline--two-columns .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left) .vertical-timeline-element-content {
    float: inherit !important;
  }
  .vertical-timeline.vertical-timeline--two-columns:before{
    margin-left:0 !important;
  }
  .vertical-timeline-element{
    margin-top:0 !important;
  }

  .desktopView .vertical-timeline.vertical-timeline--two-columns {
    width: 100%;
}
  .vertical-timeline--two-columns .vertical-timeline-element-content{
    width: 90% !important;
  }
  .desktopView .ci-trackerOrder .mainDivHeadingTimeline {
    height: auto !IMPORTANT;
    
  }
  .desktopView .ci-trackerOrder {
    height: auto ;
  }
  }
  .listViewDetailButton{
    background-color: #F0F8FE;
    position:absolute;
    right: 0px;
    border-radius: 4px;
    border: none;
    height: 37px !important;
    width: 112px !important;
    font-size:16px;
  }
  
  

@media only screen and (min-width: 1024px) {
  .successImg{
    width:150px !important;
  }
  .need_help_box button {
    
  }
}
@media only screen and (max-height: 830px) {
  .feedbackDetail {
    height: 75vh;
}
}
@media only screen and (max-height: 780px) {
  .feedbackDetail {
    height: 80vh;
}
}
@media only screen and (max-height: 640px) {
  .feedbackDetail {
    height: 70vh;
}
}
@media only screen and (max-height: 590px) {
  .feedbackDetail {
    height: 60vh;
 }
}

@media only screen and (max-height: 590px) {

  .mylocationDiv {
    bottom: 43%;
  }
  .mylocationDiv, .viewListDiv {
    width: 20px;
    height: 20px;
  }
  .mylocationDiv svg {
    padding: 0;
  }
  .viewListDiv {
    bottom: 34%;
  }
  .viewListDiv svg {
    padding: 0;
    font-size:20px;
  }

}
.available-list-slider .card-layout{
  width: 95% !important;
}

.available-list-slider .card-layout .locationName{
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}
.available-list-slider .card-layout .address-lines{
  width: calc(100% - 60px);
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #727272;
  padding: 8px 0px;
}
.available-list-slider .card-layout .timing-detail{
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #727272;
    padding-top: 10px;

}
.available-list-slider .card-layout .timing-detail .timing{
  width: calc(100% - 60px);
}
.available-list-slider .card-layout .timing-detail .arrow-forward {
  float: right;
  text-align: right;
  width: 60px;
}

.msg-to-fe-mobile .page-header{
  position: fixed;
  top: 0;
  height: 60px;
  width: 100%;
  color: #FFFFFF;
  background-color: #0460A9;
}
.msg-to-fe-mobile .page-header p{
  padding: 18px 16px 18px 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}
.left-arrow {
  border: solid #FFFFFF;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 6px;
  transform: ${ languageDirection == "rtl" ?  'rotate(-135deg)' : 'rotate(135deg)'};
  -webkit-transform: ${ languageDirection == "rtl" ?  'rotate(-135deg)' : 'rotate(135deg)'};
}
.need-help-call{
  transform: ${ languageDirection == "rtl" ?  'rotate(-100deg)' : 'rotate(0deg)'};
  -webkit-transform: ${ languageDirection == "rtl" ?  'rotate(-100deg)' : 'rotate(0deg)'};
}
.msg-to-fe-desktop .message-div .MuiFormControlLabel-root{ 
  margin-left: -11px;
  margin-right: 16px;
}

.msg-to-fe-mobile .page-footer{
  position: fixed;
  bottom: 8px;
  width: calc(100% - 8px);
  padding: 4px;
  height: 40px;
  background: white;
}

.msg-to-fe-mobile .page-footer .footer-buttons{
  width: calc(50% - 2px);
  padding: 10px 4px;
  line-height: 20px;
}
.msg-to-fe-mobile .page-footer .left-btn{
  margin-right: 2px;
  background-color: #F0F8FF;
  color: #0460A9;
}
.msg-to-fe-mobile .page-footer .right-btn{
  margin-left: 2px;
}

.msg-to-fe-mobile .page-body{
  color: #212121;
  position: absolute;
  padding: 32px 8px 16px 16px;
  margin-top: 5px;
  height: calc(100% - 155px);
  width: calc(100% - 24px);
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
}
.msg-to-fe-mobile .page-body .supporting-txt , .msg-to-fe-desktop .page-body .supporting-txt{
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;
}
.msg-to-fe-mobile .message-div .MuiFormControlLabel-root{
  margin-left: -11px;
  margin-right: 16px;
}
.page-body .message-div .pre-message{
  height: 20px;
  margin-bottom: 20px;
}
.page-body .message-div .pre-message span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1{
  line-height: 20px;
  font-size: 14px;
  word-break: break-word;
}
.page-body .message-div .pre-message.selected-msg span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1{
  font-weight: 500;
}
.page-body .cust-message-box .MuiTextField-root{
  width: 95%;
}
.page-body .cust-message-box .MuiOutlinedInput-multiline {
  padding: 8px 12px;

}
.page-body .cust-message-box textarea{
  min-height: 40px;
}
.page-body .cust-message-box .char-num{
  margin-right: 5%;
  float: right;
  font-size: 14px;
}
.msg-to-fe-desktop , .msg-to-fe-mobile{
  font-family: Roboto;
}


.msg-to-fe-desktop .page-header{
  padding: 20px 24px;
  color: #FFFFFF;
  background-color: #0460A9;
}

.msg-to-fe-desktop .page-header h2.MuiTypography-root.MuiTypography-h6{
    line-height: 20px;
    font-size: 18px;
    font-weight: 500;
}
.msg-to-fe-desktop .page-header .close-icon{
  float: right;
}
.msg-to-fe-desktop .page-header .close-icon button.MuiButtonBase-root {
  color: #FFFFFF;
  height: 20px;
  padding: 0px;
}
.msg-to-fe-desktop .page-footer{
  background-color: #F3F3F3;
}
.msg-to-fe-desktop .page-footer .footer-buttons{
  height: 40px;
  margin-right: 16px;
}
.msg-to-fe-desktop .page-body {
  margin-top: 24px;
}

.page-footer .footer-buttons.right-btn.MuiButton-containedPrimary{
  background-color: #0460A9;
  color: #FFFFFF;
}
.page-footer .footer-buttons.left-btn.MuiButton-textPrimary{
  color: #0460A9;
}

.page-footer .footer-buttons.right-btn.MuiButton-contained.Mui-disabled {
  color: #ADADAD;
  background-color: #F3F3F3;
}

.page-body .cust-message-box .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #DADADA;
  border-width: 2px;
}
.page-body .cust-message-box .MuiOutlinedInput-notchedOutline {
  border-color: #DADADA;
  border-width: 1px;
}  
.prev-job-display-message {
  z-index: 10000;
  position: sticky;
  bottom: 0;
  background-color: #212121;
  text-align: center;
}

.desktop-view-market-asset .carousel-item {
  width:100% ;
  height:100%;
  display:flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.desktop-view-market-asset .carousel-item img {
  vertical-align: top;
  width: 260px;
  height:260px;
  backface-visibility: hidden;
}

.desktop-view-market-asset .swiper-button-prev {
  color: #323232;
  opacity: 0.5;
  border-radius: 8px;
  :hover {
    opacity: 1;
  }
  :after {
    font-size: 25px;
    font-weight: bold;
  }
}

.desktop-view-market-asset .swiper-button-next {
  color: #323232;
  opacity: 0.5;
  border-radius: 8px;
  :hover {
    opacity: 1;
  }  
  :after {
    font-size: 25px;
    font-weight: bold;
  }
}

.layout-scr-market-asset .carousel-item {
  width:100%;
  height:100%;
  display:flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.layout-scr-market-asset .carousel-item img {
  vertical-align: top;
  width: 144px;
  height: 144px;
  backface-visibility: hidden;
}

.layout-scr-market-asset .carousel-item .swiper-button-next {
  visibility: hidden;
}

.layout-scr-market-asset .carousel-item .swiper-button-prev {
  visibility: hidden;
}

/** Return Instruction CSS **/
.return-instruction-box {
  background-color: #fff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 2px 8px rgb(33 49 66 / 4%);
  border-radius: 8px;
  width: 100%;
  padding: 21px 16px 0;
  margin:32px 0;
}
.return-instruction-box .return-instructions-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #dadada;
  padding-bottom: 12px;
  margin-bottom: 24px;
}

.return-instruction-box .return-instructions-header h3 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #212121;
}

.return-instruction-box .return-instructions-header a {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #0460a9;
  padding: 7px 12px;
}

.return-instruction-box .return-instructions-header a:hover{
  color: #0460a9;
}

.return-instruction-step h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #212121;
  margin: 0 0 2px 0;
  word-break: break-word;
}
.return-instruction-step p{
  font-size: 14px;
  line-height: 20px;
  color: #727272;
  margin: 0;
  padding: 0;
}
.return-instruction-box .return-instruction-steps {
  counter-reset: row-num;
  width: 100%;
}
.return-instruction-step {
  margin-bottom: 32px;
  width: 100%;
  position: relative;
  padding-left: 44px;
  counter-increment: row-num;
}
.return-instruction-step:before {
  content: counter(row-num);
  position: absolute;
  width: 32px;
  height: 32px;
  background: var(--secondary-bg-color);
  border-radius: 90px;
  left: 0;
  top: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.return-instruction-step p h5 {
  font-size: 14px;
  line-height: 20px;
  color: #212121;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 4px;
}
.return-instruction-step p address {
  font-size: 12px;
  line-height: 18px;
  color: #727272;
  margin: 0;
}
.return-instruction-step p a {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-bg-color);
  margin: 0;
  margin-top: 8px;
  display: inline-flex;
  align-items: center;
}
.return-instruction-step p a:hover{
  color: var(--primary-bg-color);
}
.return-instruction-step p a svg{
  margin-right: 5px;
  color: #0460a9;
}
.monkey-survey-form-group-row .MuiCheckbox-colorSecondary.Mui-checked {
  color:var(--primary-bg-color);
}
.monkey-survey-form-group-row .monkey-survey-form-delivery-date .MuiTypography-body1{
  font-size: 14px !important;
  font-family: 'Lato' !important;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.monkey-survey-form-delivery-textarea .ant-radio-checked .ant-radio-inner{
  border-color: var(--primary-bg-color) !important ;
}

.monkey-survey-form-delivery-textarea .ant-radio-checked .ant-radio-inner:after{
  background-color: var(--primary-bg-color);
}

.monkey-survey-form-delivery-textarea .ant-radio:hover .ant-radio-inner {
  border-color: var(--primary-bg-color) ;
}
.order-return-label-btn {
  background: var(--primary-bg-color);
  border-radius: 4px;
  border: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 7px 12px;
  color: #FFFFFF;
  font-family: 'roboto', sans-sarif;
  margin-top: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.order-return-label-btn svg{
  margin-right: 6px;
}
/** Return Instruction CSS **/

.orderInfo .ci-order-label-title {
  font-size: 14px;
  line-height: 20px;
  color: #727272;
  text-align:left;
  font-weight:400;
}
.orderInfo .ci-order-value-title {
  font-size: 14px;
  line-height: 20px;
  color: #212121;
  text-align:right;
  unicode-bidi: plaintext !important;
}
.ci-desktopView .rightpanel{
  position: absolute;
  right: 40px;
  top:40px
}
.ci-desktopView{
    // padding: 20px 0;
    margin: 0px auto;
    width: 960px;
}
.ci-background-grey {
  background-color: #F3F3F3;
  width: 100%;
  min-height: calc(100vh - 110px);
  padding-bottom: 24px;
}
.non-sticky{
  position:relative;
  height: 55px;
}
.desktopView .address-detail p.ci-add-label{
  font-weight:400 !important;
  font-family: 'roboto',sans-serif;
  color: #727272 !important;
}
.desktopView .address-detail .contact-sec span.ci-con-label{
  font-weight:400 !important;
  font-family: 'roboto',sans-serif;
  color: #727272 !important;
}
.align-right{text-align:right;}
.max-min-width{
  min-width: 178px;
  max-width: 260px;
}
.ci-pudo-return-map-view-mobile{
  display:none;
}

/* Order Info MobileView CSS */

/** CI v3 Desktop Order and Timeline New CSS***/
.desktop-v3-order-timeline-container {
  width: 100%;
  margin-top: 20px;
}
.desktop-v3-order-timeline-container .MuiExpansionPanel-root {
  margin-bottom: 16px !important;
  border: 2px solid #F3F3F3;
}
.desktop-v3-order-timeline-container .MuiExpansionPanel-root .MuiExpansionPanelSummary-content.Mui-expanded{
  margin: 0 !important;
}
.desktop-v3-order-timeline-container .MuiExpansionPanelSummary-root {
  background: #F3F3F3;
  border-radius: 4px 4px 0 0;
  height: 46px !important;
  min-height: 46px !important;
  border: 0;
  padding: 0px 20px;
}
.desktop-v3-order-timeline-container .MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content .MuiTypography-body1{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.desktop-v3-order-timeline-container .MuiExpansionPanel-root:before {
  display: none;
}
.desktop-v3-order-inner-box {
  width: 100%;
  display: flex;
}
.desktop-v3-order-inner-box .orderInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  margin-bottom:0 !important;
}
.desktop-v3-order-inner-box .orderInfo .box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  border-bottom: 1px solid #DADADA;
  padding: 16px 0px !important;
}
.desktop-v3-order-inner-box .orderInfo .box:last-child{
  border-bottom: 0;
}
.desktop-v3-order-inner-box .orderInfo .box span:first-child {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 2px;
  display: block;
  text-align: left;
  color: #727272;
}
.desktop-v3-order-inner-box .orderInfo .box span:last-child {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 2px;
  display: block;
  text-align: left;
  color: #212121;
}
.desktop-v3-timeline-inner-box {
  width: 100%;
}

.desktop-v3-timeline-inner-box .bg-white.mainDivHeadingTimeline {
  padding: 24px 20px 16px !important;
  height: auto !important;
  max-height: 382px !important;
  margin: 0 !important;
  overflow: hidden;
  overflow-y: auto;
}
.desktop-v3-timeline-inner-box .mainDivHeadingTimeline .vertical-timeline .vertical-timeline-element .vertical-timeline-element-icon,
.mainDivHeadingTimeline .vertical-timeline .vertical-timeline-element .vertical-timeline-element-icon {
  width: 16px !important;
  height: 16px !important;
  background-color: #0460A9;
}
.status_log_screen .mainDivHeadingTimeline .vertical-timeline::before {
  left: 7px !important;
  background-color: #0460A9;
  width: 2px;
  height:calc(100% - 0px)
}
.desktop-v3-timeline-inner-box .mainDivHeadingTimeline .vertical-timeline::before {
  left: 7px !important;
  background-color: #0460A9 !important;
  width: 2px;
  height:calc(100% - 0px)
}
.desktop-v3-timeline-inner-box .mainDivHeadingTimeline .vertical-timeline .statusRemarkDiv .pb20 p,
.mainDivHeadingTimeline .vertical-timeline .statusRemarkDiv .pb20 p {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 20px;
  color: #212121;
}
.desktop-v3-timeline-inner-box .mainDivHeadingTimeline .vertical-timeline .vertical-timeline-element .DateTimeDiv p.date-timeline {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #212121 !important;
}
.desktop-v3-timeline-inner-box .mainDivHeadingTimeline .vertical-timeline .vertical-timeline-element .DateTimeDiv p.time,
.mainDivHeadingTimeline .vertical-timeline .vertical-timeline-element .DateTimeDiv p.time {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #727272;
  max-height: 32px;
}
.desktop-v3-timeline-inner-box .mainDivHeadingTimeline .vertical-timeline .vertical-timeline-element .vertical-timeline-element-content,
.mainDivHeadingTimeline .vertical-timeline .vertical-timeline-element .vertical-timeline-element-content {
  width: calc(100% - 32px) !important;
  margin-left: 32px !important;
  min-height: 36px;
}
.desktop-v3-order-timeline-container #panel1a-content .MuiExpansionPanelDetails-root {
  padding: 0 !important;
}
.desktop-v3-timeline-inner-box .bg-white.mainDivHeadingTimeline .vertical-timeline-element,
.bg-white.mainDivHeadingTimeline .vertical-timeline-element {
  //padding: 0 0 25px 0 !important;
}
.desktop-v3-timeline-inner-box .bg-white.mainDivHeadingTimeline .vertical-timeline-element:last-child,
.bg-white.mainDivHeadingTimeline .vertical-timeline-element:last-child{
  padding: 0 0 0 0 !important;
}
.bg-white.mainDivHeadingTimeline {
  padding-top: 16px !important;
}
.desktop-v3-order-inner-box .orderInfo .box:first-child {
  padding-top: 0 !important;
}
.desktop-v3-order-timeline-container .desktop-v3-order-tab #panel1a-content .MuiExpansionPanelDetails-root {
  padding: 20px 20px 0 !important;
  max-height: 382px;
  overflow: auto;
}
.desktop-v3-order-inner-box .orderInfo .p10 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  border-bottom: 1px solid #DADADA;
  padding: 16px 0px !important;
}
.desktop-v3-order-inner-box .orderInfo .p10 span:first-child {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 2px;
  display: block;
  text-align: left;
  color: #727272;
}
.desktop-v3-order-inner-box .orderInfo .p10 span:last-child {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  display: block;
  text-align: left;
  color: #212121;
}
.desktop-v3-order-inner-box .orderInfo .p10:last-child{
  border-bottom: 0;
}
.desktop-v3-order-inner-box .orderInfo .p10:first-child {
  padding: 0 0 16px !important;
}
.desktopView .vertical-timeline.vertical-timeline--two-columns,
.status_log_screen .vertical-timeline.vertical-timeline--two-columns{
  padding-top: 22px !important;
}
.trackerStatus.vertical-timeline.vertical-timeline--animate.vertical-timeline--two-columns {
  padding-top: 10px !important;
}

.desktopView .vertical-timeline.vertical-timeline--two-columns:first-child,
.status_log_screen .vertical-timeline.vertical-timeline--two-columns:first-child {
  padding-top: 0 !important;
}
.help_screen ul > div > .MuiTypography-root {
  padding: 0 16px;
}
.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date{
  display: none !important;
}
.status_log_screen .vertical-timeline-element .pb20 {
  padding-bottom: 10px !important;
}
.status_log_screen .vertical-timeline:last-child .vertical-timeline-element .pb20,
.desktop-v3-timeline-inner-box .vertical-timeline:last-child .vertical-timeline-element .pb20{
  padding-bottom: 0 !important;
}
.desktopView .web-scroll-tracker .MuiCollapse-container .status_log_screen {
  padding: 24px 20px 16px !important;
  height: auto !important;
  max-height: 382px !important;
  margin: 0 !important;
  overflow: hidden;
  overflow-y: auto;
}
.desktopView .web-scroll-tracker .MuiCollapse-container .status_log_screen .bg-white.mainDivHeadingTimeline{
  padding: 0;
}
.desktopView .web-scroll-tracker .MuiCollapse-container .MuiExpansionPanelDetails-root{
  padding:0 !important;
}
.desktopView .web-scroll-tracker .MuiPaper-root .MuiExpansionPanelSummary-root {
  margin-bottom: 0 !important;
}
/** CI v3 Desktop Order and Timeline New CSS***/
/******CI Pudo Desktop Map Modal*******/
.ci-pudo-return-desktop-map-modal {
  position: fixed;
  box-shadow: 0px 4px 14px rgb(0 0 0 / 18%), 0px 25.6px 57.6px rgb(0 0 0 / 22%);
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;	
  align-items: center;	
  justify-content: center;	
  align-content: center;
}

/***CI Pudo Store and Order Details DesktopCSS****/
.ci-pudo-order-details-desktop > *,
.ci-pudo-order-details-mobile > * {
  // font-family: 'Lato';
}
.ci-pudo-order-details-desktop-container {
  width: 468px;
  #border: 2px solid #F3F3F3;
  border-radius: 8px;
  background: #fff;
  padding: 24px 32px;
  margin-bottom: 24px;
}
.ci-pudo-order-details-banner {
  width: 100%;
  display: flex;
  margin-bottom: 32px;
}
.ci-pudo-order-details-banner-img {
  width: 160px;
  margin-right: 30px;
}
.ci-pudo-order-details-banner-img img {
  height: 120px;
}
.ci-pudo-order-details-banner-text h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.2px;
  color: #05223D;
  margin-bottom: 8px;
}
.ci-pudo-order-details-banner-text span {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #05223D;
}
.ci-pudo-order-store-details-box h4 {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #647788;
  margin-bottom: 16px;
}
.ci-pudo-order-store-details-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ci-pudo-order-store-details-row {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  min-height:32px;
}
.ci-pudo-order-store-details-row:last-child {
  margin-bottom:0;
}
.ci-pudo-order-store-details-row p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #05223D;
  margin: 0;
}
.ci-pudo-order-store-details-row > svg {
  margin-right: 16px;
  margin-top: 2px;
}

.ci-pudo-order-store-details-row.listView > svg{
  margin-top: 14px;
  margin-right: 7px;
}
.ci-pudo-order-store-details-row p a {
  display: block;
}
.ci-pudo-order-store-details-row p strong {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #24A148;
  margin-right: 4px;
  cursor:pointer
}
.ci-pudo-order-store-details-row .no-anchor {
  color: #05223D;
  cursor: auto;
}
.ci-pudo-tracking-details-desktop-container {
  width: 468px;
  border: 2px solid #F3F3F3;
  border-radius: 8px;
  background: #fff;
  padding: 32px;
  margin-bottom: 16px;
}
.ci-pudo-tracking-details-desktop-row {
  width: 100%;
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
  align-items: center;
}
.ci-pudo-tracking-details-desktop-row:last-child{
  margin-bottom: 0;
}
.ci-pudo-tracking-details-desktop-row span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #647788;
  width: 140px;
}
.ci-pudo-tracking-details-desktop-row label{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #05223D;
  width: calc(100% - 140px);
  text-align: right;
}
.ci-pudo-detail-quick-action-div {
  width: 100%;
  background: #FAFAFC;
  padding: 24px 0px 8px;
  position: relative;
  z-index: 1;
  text-align: center;
}
.ci-pudo-detail-quick-action-delivery {
  width: 132px !important;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 0.6px 2.8px rgb(5 34 61 / 6%);
  border-radius: 8px;
  padding: 16px 0;
  margin: 0 16px 16px;
  cursor: pointer;
  display: inline-block;
  height:136px !important;
}
.ci-pudo-detail-quick-action-delivery-order {
  width: 330px !important;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 0.6px 2.8px rgb(5 34 61 / 6%);
  border-radius: 8px;
  padding: 16px 0;
  margin: 0 16px 16px;
  cursor: pointer;
  display: inline-block;
  height:48px !important;
}
.ci-pudo-detail-quick-action-delivery span {
  width: 32px;
  height: 32px;
  background: #F3F3F3;
  border-radius: 90px;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.ahhho span {
  display: inline-block;
}
.ci-pudo-detail-quick-action-delivery p {
  margin-top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #05223D;
  margin-bottom: 0;
  padding: 0 14px;
  // font-family: 'Lato';
}
.ci-pudo-detail-quick-action-div h4 {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #647788;
}
.ci-pudo-detail-quick-action-boxes {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
/***Mobile CSS***/
.ci-pudo-order-details-mobile .ci-pudo-order-details-desktop-container {
  width: 100%;
  border: 0;
  background: none;
  padding: 0;
}
.ci-pudo-order-details-mobile .ci-pudo-order-details-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ci-pudo-order-details-mobile .ci-pudo-order-details-banner-text {
  text-align: center;
  margin-top: 32px;
}
.ci-pudo-detail-get-direction-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #CBD3DF;
  justify-content: space-around;
  height:45px;
}
.get-direction-left:nth-child(2) {
  border-left: 1px solid #CBD3DF;
  height:45px;
}
.get-direction-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 10px;
}
.ci-pudo-order-details-mobile .ci-pudo-tracking-details-desktop-container {
  width: 100%;
  padding: 0;
  border: 0;
  margin: 32px 0 32px;
}
.get-direction-left a {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #05223D;
}
.get-direction-left a svg {
  margin-top:  4px;
  margin-right: 8px;
}
.pudo-mobile-call a svg {
  margin-top:  7px !important; 
  margin-right: 8px;
}
.ci-pudo-detail-quick-action-mobile .mobile-scroll {
  overflow: auto;
}
.ci-pudo-detail-quick-action-mobile .ci-pudo-detail-quick-action-delivery {
  margin: 0 16px 0 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
.ci-pudo-detail-quick-action-mobile .ci-pudo-detail-quick-action-boxes  {
  min-width: 1330px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.ci-pudo-detail-quick-action-mobile {
  margin-top: 32px;
}
.ci-pudo-detail-quick-action-mobile > h4 {
  // font-family: 'Lato';
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #647788;
}
.ci-pudo-detail-quick-action-mobile .mobile-scroll::-webkit-scrollbar {
  width: 0;
}
.mobileQuickAction{
  text-align:left;
  padding-left:20px;
}
/***Mobile CSS***/
/***CI Pudo Store and Order Details DesktopCSS****/

@media screen and (max-width:767px){
  .orderInfo .ci-order-value-title {
    text-align: left !important
  }
  .ci-tracking-map-conatiner {
    padding: 0;
  }
  .re-center {
    right: 16px;
  }
  .ci-tracking-map-conatiner > #map {
    height: ${isEmbed ?'100vh !important':'360px !important'};
  }
  .ci-map-mobile-divider {
    padding-top: 7px;
    background-color: #f1f1f1;
  }
}

.ci-pudo-return-desktop-map-modal:after {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
}
.ci-pudo-return-desktop-map-modal-container{
  width: 100%;
  max-width: 960px;
  position: relative;
  z-index: 2;
  padding: 0;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
}
.returns-modal-box-map-header {
  width: 100%;
  padding: 22px 22px 22px 24px;
  background: #0460A9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  border-radius: 4px 4px 0px 0px;
}
.returns-modal-box-map-header h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  margin: 0;
}
.returns-modal-box-map-header button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 20px;
  height: 20px;
}
.ci-pudo-return-desktop-map-modal-body {
  width: 100%;
  position: relative;
  min-height: 560px;
}
.ci-pudo-return-desktop-map-frame {
  width: 100%;
  height: 560px;
  padding-left: 375px;
}
.ci-pudo-return-desktop-map-store-list {
  position: absolute;
  top: 3px;
  left: 5px;
  width: 376px;
  -webkit-transition: all .15s ease;
  transition: all .15s ease;
  // overflow: hidden;
  z-index: 3;
}
.returns-map-list-search-field {
  padding: 8px 30px 8px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #f3f3f3;
  filter: drop-shadow(0px 6.4px 13.4px rgba(0, 0, 0, 0.13)) drop-shadow(0px 4.8px 3.6px rgba(0, 0, 0, 0.1));
  z-index: 1;
}
.returns-map-list-back-arrow {
  border: 0;
  background: no-repeat;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  width: 35px;
  height: 35px;
  margin-right: 15px;
  outline: none;
  transform: scaleX(1);
}
.returns-map-list-search-field .returns-map-list-search-input {
  border: 0;
  padding: 12px 20px 12px;
  padding-left: 0;
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  line-height: 16px;
  color: #212121;
  width: calc(100% - 50px);
}
.ci-pudo-return-desktop-map-modal .ant-input:focus, 
.ci-pudo-return-desktop-map-modal .ant-input-focused {
  border-color: inherit;
  box-shadow: 0 0 0 0px rgb(24 144 255 / 20%);
  border-right-width: 0;
  outline: 0;
}
.ci-pudo-return-desktop-map-modal .returns-map-list-search-input:focus-visible{
  border-color: inherit;
  box-shadow: 0 0 0 0px rgb(24 144 255 / 20%);
  border-right-width: 0;
  outline: 0;
}
.ci-pudo-return-desktop-map-modal-body .pudo-map{
  height: 560px;
}
.ci-pudo-return-map-view-mobile  .pudo-map{
  height: 100vh;
}
.returns-map-list-search-icon {
  position: absolute;
  right: 10px;
  top: 12px;
  border: 0;
  background: 0;
  height: 35px;
  width: 35px;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  outline: none;
}
.returns-map-list-group {
  width: 100%;
  padding: 20px;
  position: relative;
  z-index: 2;
  height: 492px;
  overflow-y: auto;
  counter-reset: rowNumber;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 6.4px 13.4px rgb(0 0 0 / 13%), 0px 4.8px 3.6px rgb(0 0 0 / 10%);
  border-radius: 4px;
}
.simple-list-store-detail {
  padding: 12px;
  border: 1px solid transparent;
  background-color: #fff;
  border-bottom: 1px solid #dadada;
  padding-bottom: 20px;
  margin-bottom: 16px;
  border-radius: 4px;
}
.simple-list-store-detail.highlighted-store-detail {
  border-color: var(--primary-color);
  box-shadow: 0 3px 18px rgb(33 49 66 / 20%);
  background-color: #f4f4f4;
}
.returns-map-list-address-row {
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  counter-increment: rowNumber;
}
.returns-map-list-address-name {
  width: calc(100% - 100px);
}
.returns-map-list-address-name h4 {
  font-size: 16px;
  line-height: 22px;
  color: #212121;
  font-weight: 500;
  word-break: break-word;
}
.returns-map-list-address-group {
  width: 100%;
  display: flex;
  margin-top: 10px;
}
.returns-map-list-point-num {
  width: 25px;
  margin-right: 10px;
  position:relative;
}
.returns-map-list-point-num span.address-map-shape {
  position: absolute;
  top: 0;
  left: 0;
}
span.address-map-shape {
  width: 25px;
  height: 32px;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 0 12px 0;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: center center;
  position: relative;
  color: #fff;
}
.returns-map-list-point-right p {
  font-size: 12px;
  line-height: 16px;
  color: #727272;
}
.returns-map-list-point-right span {
  font-size: 12px;
  line-height: 16px;
  color: #727272;
  margin-top: 4px;
  display: block;
}
.returns-map-list-address-dtl {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
}
button.nearest-btn {
  background: #4a90e2;
  border-radius: 4px;
  border: 0;
  font-size: 12px;
  line-height: 14px;
  padding: 4px 8px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 12px;
  height: 22px;
}
.ci-pudo-return-swiper-details .nearest-btn {
  position: absolute;
    right: 15px;
    top: 8px;
    margin-bottom: 0px;
}
.returns-map-list-address-dtl h6 {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #727272;
}
.pudo-map-select-arrow-div {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}
.pudo-map-select-arrow {
  border: 0;
  cursor: pointer;
  padding: 7px 12px;
  width: 58px;
  background: #F0F8FF;
  border-radius: 4px;
}
#selectButtonPudoCard{
  width: 90px;
  padding: 6px 6px;
}
.returns-map-list-view {
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 6.4px 13.4px rgb(0 0 0 / 13%), 0px 4.8px 3.6px rgb(0 0 0 / 10%);
  border-radius: 4px;
}
.simple-list-store-detail:last-child {
  border: 0;
}
.return-store-pudo-web-view {
  position: absolute;
  top: 68px;
  left: 392px;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0px 6.4px 13.4px rgb(0 0 0 / 13%), 0px 4.8px 3.6px rgb(0 0 0 / 10%);
  border-radius: 8px;
  width: 368px;
}
.store-timing-list{
  position: absolute;
  z-index: 40;
  background-color: #fff;
  box-shadow: 0px 6.4px 13.4px rgb(0 0 0 / 13%), 0px 4.8px 3.6px rgb(0 0 0 / 10%);
  border-radius: 8px;
  width: 150px;
  display: none;
}

.timing-hover-arrow:hover + .store-timing-list {
  display: block;
}
.return-pudo-store-dtl-inner {
  width: 100%;
  padding: 16px 24px;
  position: relative;
}
.return-pudo-store-web-close {
  position: absolute;
  right: 16px;
  top: 18px;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
}
.return-pudo-store-name-title h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #05223d;
  margin-bottom: 4px;
}
.return-pudo-store-name-title span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #647788;
}
.return-pudo-store-outer-box {
  width: calc(100% + 24px);
  margin-top: 10px;
  max-height: 320px;
  overflow: hidden;
  overflow-y: auto;
}
.return-pudo-store-list-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.return-pudo-store-img-icon {
  background: #f0f8ff;
  border-radius: 90px;
  width: 32px;
  height: 32px;
  margin-right: 12px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.return-pudo-store-data-col {
  width: calc(100% - 44px);
  margin-top: 0;
  border-bottom: 0;
}
.drop-down-store-timings .ant-dropdown{
  z-index: 10500 !important;
} 
.timing-hover-arrow{
  color: #3F98D8 !important;
  height: 3px !important;
}
.return-pudo-store-data-col p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #05223d;
  margin: 0;
}
.return-pudo-store-data-col a {
  display: inline-flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3f98d8;
  margin-top: 4px;
  cursor: pointer;
}
.return-pudo-store-ul-list {
  width: 100%;
  display: flex;
  padding-top: 10px;
}
.return-pudo-store-img-icon {
  background: #f0f8ff;
  border-radius: 90px;
  width: 32px;
  height: 32px;
  margin-right: 12px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.return-pudo-store-data-ul-col {
  width: calc(100% - 44px);
}
ul.return-pudo-store-info-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
ul.return-pudo-store-info-list li {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #05223d;
  position: relative;
  padding-left: 10px;
  padding-right: 6px;
  width: 100%;
}
.return-pudo-store-info-list li:before {
  content: "-";
  position: absolute;
  top: 0;
  left: 0;
}
.return-pudo-web-confirm-btn {
  background: #3f98d8;
  border-radius: 4px;
  border: 0;
  padding: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  width: 100%;
  margin-top: 16px;
  cursor: pointer;
}
.return-pudo-store-data-col .down-arrow {
  border: 0;
  background: none;
  cursor: pointer;
}
/******CI Pudo Desktop Map Modal*******/
@media screen and (max-width:767px){
  .orderInfo .ci-order-value-title {
    text-align: left !important
  }
  .ci-tracking-map-conatiner {
    padding: 0;
    margin-top:13px;

  }
  .re-center {
    right: 16px;
  }
  .ci-tracking-map-conatiner > #map {
    height:  ${isEmbed ?'100vh !important':'360px !important'};
  }
  .ci-map-mobile-divider {
    padding-top: 7px;
    background-color: #f1f1f1;
  }

  .layout-scr-market-asset .background-grey.desktop .banner-image-image {
      border-radius: 0 !important;
  }
  .social-inner-wrapper.non-sticky {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    height: auto;
    padding: 6px 10px 22px;
}
  .social-inner-wrapper.non-sticky p.text-gray {
    width: 100%;
    text-align: center;
    padding-top: 14px;
  }
  .social-media-links .extrainfo {
    max-width: 100%;
    position: relative;
    width: 100%;
    text-align: center;
    padding: 0!important;
  }
  .social-media-links .extrainfo span{
    padding: 14px 0 0 !important;
    display: inline-block;
    font-weight: 300;
  }
  .layout-screen-customize .layout-screen-mobile-wrapper {
    min-height: calc(100vh - 152px);
  }
  .social-inner-wrapper.non-sticky p.text-gray span span {
    margin: 0 10px!important;

  }
}
.position-rtv {
  position: relative;
}
.ci-container-bg-img {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}
.ci-container-bg-img img {
  max-width: 100%;
  height: auto;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.ci-v3-desktop-animation- {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  overflow:hidden;
}
/****CI Pudo Return Map CSS Mobile****/
.ci-pudo-return-map-view-mobile > *,
ci-pudo-return-map-list-view-mobile > *,
ci-pudo-return-store-details-container > * {
  // font-family: 'Lato';
}
@media screen and (max-width:767px){
  .ci-pudo-return-map-view-mobile {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background: #fff;
    z-index: 9990;
    display: block;
  }
  .ci-pudo-return-map-header-search-bar {
    width: 100%;
    padding: 12px 16px;
    display: flex;
    position: relative;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(26, 43, 58, 0.11);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .ci-pudo-return-map-header-search-bar .search-icon {
    position: absolute;
    right: 16px;
    top: 13px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: none;
    z-index: 1;
}
.ci-pudo-return-map-header-search-bar .back-arrow {
  border: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 32px;
  height: 40px;
  padding:0;

}
.ci-pudo-return-map-header-search-bar .input-search-field {
  border: 1px solid #ADADAD;
  border-radius: 8px;
  padding: 10px 32px 10px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #212121;
  width: calc(100% - 32px);
}
ci-pudo-return-map-container-view {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 1;
}
.ci-pudo-return-map-frame-view img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin: 0;
}
.ci-pudo-return-map-frame-view {
  width: 100%;
  padding-top: 66px;
}
.ci-pudo-return-bottom-drawer {
  position: absolute;
  bottom: 8px;
  width: 100%;
  left: 8px;
}
.ci-pudo-return-swiper .ci-pudo-return-swipe-slider {
  background: #FFFFFF;
  border: 1px solid #CBD3DF;
  border-radius: 16px;
  padding: 20px 16px 24px;
  width: calc(100% - 8px);
  margin-right: 8px;
  //max-height: 170px; 
  height: 165px;
}

.ci-pudo-return-gpd-icon {
  position: absolute;
  right: 20px;
  bottom: 240px;
  background: #fff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 2px 4px rgb(255 255 255 / 50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
}
.ci-pudo-return-list-view-icon {
  position: absolute;
  right: 20px;
  bottom: 184px;
  width: 105px;
  height: 36px;
  border-radius: 4px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  background: #3F98D8;
  box-shadow: 0px 0px 2px rgba(4, 96, 169, 0.08), 0px 2px 6px rgba(4, 96, 169, 0.13);
}
.ci-pudo-return-list-view-icon p{
  margin: 0px;
  padding-left: 5px;
  color: #FFFFFF;
}
.ci-pudo-return-swiper-list {
  width: 100%;
  display: flex;
  align-items: center;
}
.ci-pudo-return-swiper-list button {
  padding: 0;
  position: relative;
  border: 0;
  background: none;
  height: 32px;
  width: 24px;
  margin-right: 8px;
}
.ci-pudo-return-swiper-list button span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  margin-left: 0;
}

.ci-pudo-return-swiper-list h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #05223D;
  margin: 0;
  width: calc(100% - 112px);
}
.ci-pudo-return-swiper-list h6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: #647788;
  width: 80px;
  margin: 0;
  margin-top:2px;
}
.ci-pudo-return-swiper-address p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #647788;
  margin: 0;
}
.viewDetailPudoCard{
    background: #F0F8FE;
    border-radius: 4px;
    margin-left: calc(100VW - 200px);
    width:112px !important;
    padding:6px;
    height:43px;
    font-size:16px;
    border-width:0;
    margin-top: 5px;
    margin-bottom: 5px;
}
.ci-pudo-return-swiper-address {
  margin-top: 8px;
}
.ci-pudo-return-swiper-timer {
  margin-top: 16px;
  width: 100%;
  display: flex;
  align-items: center;
}
.ci-pudo-return-swiper-timer span {
  margin-left: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #05223D;
}
.ci-pudo-return-swiper-timer span strong {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #24A148;
  margin-right: 2px;
}
.ci-pudo-return-swiper-timer span strong.red{
  color: #DA1E28;
}
.ci-pudo-return-bottom-drawer .title-h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #212121;
  margin: 0 0 8px;
  // font-family: 'Lato';
  font-style: normal;
  padding-left:24px;
}
.ci-pudo-return-map-header-search-bar h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #05223D;
  margin: 0;
  width: calc(100% - 50px);
  // font-family: 'Lato';
  font-style: normal;
}
.ci-pudo-return-map-header-search-bar .dark-search-icon {
  background: #F3F4F4;
  border-radius: 32px;
  border: 0;
  padding: 4px;
  width: 28px;
  height: 28px;
}
/*list map css*/
.ci-pudo-return-map-list-view-mobile {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: #fff;
  z-index: 9990;
  display: block;
}
.ci-pudo-return-map-list-view-mobile .ci-pudo-return-map-header-search-bar {
  box-shadow: 0 0;
  height: 64px;
}
.pudo-update-alert{
  width:100%;
  max-width:400px;
  position: absolute;
  text-align: center;
  z-index:20;
  margin-bottom:50px;
  top: 70%;     
  left: 50%;
}
.ci-pudo-return-map-preferred-store {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 64px 16px 24px;
  overflow: hidden;
  overflow-y: auto;
}
.ci-pudo-return-map-preferred-store .ci-pudo-return-swiper-details {
  background: #FFFFFF;
  border: 1px solid #CBD3DF;
  border-radius: 16px;
  padding: 24px 16px 24px;
  margin-top: 6px;
  margin-bottom: 10px;
  position: relative;
}
.ci-pudo-return-map-preferred-store .ci-pudo-return-swiper-list h3 {
  width: calc(100% - 80px);
}
.ci-pudo-return-list-view-map {
  background: #3F98D8;
  box-shadow: 0px 0px 2px rgb(21 34 45 / 8%), 0px 2px 3px rgb(21 34 45 / 13%);
  border-radius: 4px;
  padding: 10px 16px;
  border: 0;
  display: flex;
  height: 40px;
  position: fixed;
  bottom: 32px;
  right: 16px;
  z-index:1;
}
.ci-pudo-return-list-view-map span {
  // font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  margin-left: 10px;
}
/****Map PUDO Store Detail CSS****/
.ci-pudo-return-store-details-container {
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  background: #fff;
  z-index: 9999;
}
.return-store-pudo-web-view {
  position: absolute;
  top: 68px;
  left: 392px;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0px 6.4px 13.4px rgb(0 0 0 / 13%), 0px 4.8px 3.6px rgb(0 0 0 / 10%);
  border-radius: 8px;
  width: 368px;
}
.return-store-pudo-dtl-body {
  width: 100%;
}
.return-pudo-store-dtl-inner {
  width: 100%;
  padding: 24px 16px;
  position: relative;
}
.return-pudo-store-name-title h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #05223D;
  margin-bottom: 4px;
}
.return-pudo-store-name-title span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #647788;
}
.return-pudo-store-list-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 22px;
}
.return-pudo-store-img-icon {
  background: #F0F8FF;
  border-radius: 90px;
  width: 32px;
  height: 32px;
  margin-right: 12px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.return-pudo-store-data-col {
  width: calc(100% - 44px);
  padding-bottom: 0;
  margin-top: 0;
  border-bottom: 0;
  min-height: 32px;
  display: flex;
  align-items: center;
}
.return-pudo-store-data-col p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #05223D;
  margin-bottom: 0;
  border-bottom: 1px solid #F3F3F3;
  display: block;
  width: 100%;
}
.return-pudo-store-data-col p span {
  padding-right: 4px;
}
.return-pudo-store-data-col .down-arrow {
  border: 0;
  background: none;
  width: 16px;
  height: 16px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-left: 4px;
  cursor: pointer;
}
ul.return-pudo-store-info-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
ul.return-pudo-store-info-list li {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #05223D;
  position: relative;
  padding-left: 10px;
  padding-right: 6px;
  width: 100%;
}
.return-pudo-store-ul-list {
  width: 100%;
  display: flex;
  padding-top: 22px;
}
.return-pudo-store-data-ul-col {
  width: calc(100% - 44px);
  border-bottom: 1px solid #F3F3F3;
}
.return-pudo-store-info-list li:before {
  content: '-';
  position: absolute;
  top: 0;
  left: 0;
}
.return-pudo-store-data-col a {
  display: inline-flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3F98D8;
  margin-top: 4px;
  cursor: pointer;
}
.return-pudo-store-web-close {
  position: absolute;
  right: 16px;
  top: 18px;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
}
.return-pudo-web-confirm-btn {
  background: #3F98D8;
  border-radius: 4px;
  border: 0;
  padding: 16px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  width: 100%;
  cursor: pointer;
  height:48px;
}
.return-pudo-web-confirm-btn:disabled{
  background-color: #f3f3f3;
  color: #adadad;
  cursor: not-allowed;
}
.ci-return-pudo-web-confirm-btn {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgb(49 70 95 / 15%);
  padding: 12px;
  z-index: 9;
}
/****Map PUDO Store Detail CSS****/
/****Map PUDO Store Detail Mobile CSS****/

  .return-store-pudo-dtl-container {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 99;
    background: #fff;
    display: block;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 68px;
  }
  .return-store-pudo-dtl-header {
    width: 100%;
    position: sticky;
    border-bottom: 1px solid #D6DADE;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .return-store-pudo-dtl-map {
    width: 100%;
    height: 116px;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  button.return-back-arrow-pudo {
    position: absolute;
    top: 24px;
    left: 12px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
    border-radius: 24px;
    width: 36px;
    height: 36px;
    border: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
  }
.map-overlay {
  width: 100%;
  left: 0;
  bottom: 0;
  height: calc(100vh - 5.9rem);
  position: absolute;
  z-index: 10;
  background-color: rgb(0 0 0 / 50%);
}
.returns-map-list-address-row {
  border-bottom: 0;
  padding: 0;
}
.return-pudo-store-outer-box {
  width: 100%;
}
.return-pudo-store-name-title {
  margin-bottom: 20px;
}
.ci-pudo-store-detial-slides .ci-pudo-return-bottom-drawer {
  position: relative;
  left: 0;
  bottom: inherit;
}
.ci-pudo-store-detial-slides {
  padding:0 0 32px 16px;
}
.ci-pudo-return-bottom-drawer .title-h4 a {
  display: none;
}
.ci-pudo-store-detial-slides .ci-pudo-return-bottom-drawer .title-h4 a {
  display: inline-flex;
  float: right;
  margin-right: 16px;
  // font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #3F98D8;
}
  /***Map UI Modal Box CSS****/
}
/****CI Pudo Return Map CSS Mobile****/
.ci-pudo-return-desktop-map-modal .ci-pudo-return-desktop-map-frame .leaflet-top.leaflet-left {
  display: none;
}
.ci-pudo-return-map-view-mobile .ci-pudo-return-map-container-view .leaflet-top.leaflet-left {
  display: none;
}

.timeline-last-status.vertical-timeline-element:last-child {
  // background-color: transparent !important;
}

.need_help_box button.returns-lang-button{
  margin-right: 12px;
  display: flex;
  min-width: auto;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  padding: 6px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  min-height: 32px;
}

.need_help_box button.returns-lang-button .lang-code{
  margin-left: 4px;
  text-transform: uppercase;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.need_help_box button.returns-lang-button .dropdown-icon{
  display: flex;
  margin-left: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.lang-selection-popover .MuiPaper-root.MuiPaper-elevation8.MuiPopover-paper{
  margin: 5px 0px;
}
.lang-selection-popover .lang-list {
  margin: 10px;
  padding: 10px;
}
.tooltiptext {
  left: 280px;
  bottom: 35px;
  position: relative;
  visibility: hidden;
  display: none;
  border-radius:10% !important;
}
.tooltiptext.fe-chat-circle{
  height:35px !important;
  width:35px !important;
}
#editIconDiy{
  position:relative;
  right:12px;
  bottom:7px;
  height:30px !important;
  width:30px !important
}
#feDetails>button.tooltiptext{
  left: 345px;
  bottom: 40px;
}
#orderInformation>button.tooltiptext{
  left: 325px;
  bottom: 25px;
}
#etaBasicInfo>button.tooltiptext{
  left: 320px;
  bottom: 30px;
}
.etaMobile>button.tooltiptext{
  left: 80vw !important;
  bottom: 25px !important;
}
#trackerTimeLineForDiy>button.tooltiptext,#orderInformationForDiy>button.tooltiptext{
  left: 320px !important;
  bottom: 15px !important;
}
.borderHover:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  display: block;
}
#helpButtonDiy>button.tooltiptext{
    left: 870px;
    position: absolute;
    min-width: 30px !important;
    z-index: 1;
    border-radius: 50%;
    top: 24px;
    background-color:#F0F8FF !important;
}
#helpButtonDiy>button.tooltiptext #editIconDiy{
  right:4px;
  bottom:0px;
}
#marketingAsset>button.tooltiptext, #marketingBannerDiy>button.tooltiptext, #marketingCarouselDiy>button.tooltiptext{
  left: 850px;
 bottom: 17px;
}
.marketingAssetDiyMobile>button.tooltiptext{
  left: 88vw !important;
}
.borderHover:hover{
  border:2px dotted blue !important;
  border-radius:8px;
}
#etaBasicInfo.borderHover:hover{
  padding-left:10px;
}
#orderInformationForDiyMobile>button.tooltiptext.fe-chat-circle,#trackerTimeLineForDiyMobile>button.tooltiptext.fe-chat-circle {
  bottom: 10px;
  left: 87vw;
}
.helpButtonDiyMobile>button.tooltiptext{
    left:85vw !important
  }
.feMobileDiy>button.tooltiptext{
  left: 80vw !important;
}
#feedbackDiy>button.tooltiptext{
  left: 325px ;
  bottom:25px !important;
}
.feedbackDiyMobile>button.tooltiptext{
  left: 80vw !important;
}
.diyFadeOut{
  opacity: 0.3;
}
.mobileViewDetails{
  z-index:2;
  border-radius:16px !important;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 0.6px 2.8px 0px #05223D0F;
  padding:16px 16px 24px;
  margin-top:24px;
  margin-bottom:14px;
}


.orderQuickAction{
  margin-bottom:8px;
}
.desktopOrderDetails{
  padding: 10px;
  margin-left: 15px;
  border-radius: 16px;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 0.6px 2.8px 0px #05223D0F;
}
 

.newCiBackground{
  background: #F3F7FB;
}
.mobileViewOrder{
  padding:8px;
}
.mobileViewOrderDetails{
  // line-height: 20px;
  // font-size: 14px;
  background:white
  padding:4px 0px;
  display:flex;
}
.mobileViewOrderDetails>p{
  line-height:20px;
}
.orderLevel{
  z-index:2;
  border-radius:16px !important;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 0.6px 2.8px 0px #05223D0F;
  padding:16px 16px 24px;
  margin-top:24px;
  margin-bottom:14px;
}
.pt24{
  padding-top:24px !important;
}
.ci-order-quick-action {
  width: 366px !important;
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-shadow: 0px 0.6px 2.8px rgb(5 34 61 / 6%);
  border-radius: 8px;
  padding: 16px 0;
  // margin: 0 16px 16px;
  margin-left:0px;
  cursor: pointer;
  display: inline-block;
  height:48px !important;
  margin-bottom:8px;
}

.bold {
  font-weight: bold;
}





 
.shipmentList{
  width:514px;
}
.shipmentList ul{
  overflow: scroll;
  overflow-x: hidden;
  height: fit-content;
  max-height:537px;
}
.shipmentList ul::-webkit-scrollbar{
  width:8px;
  height:352px;

  }
  .shipmentList ul::-webkit-scrollbar-thumb{
  background:#CBD3DF;
}


.shipment2 {	
  border-top: 1px solid #F3F3F3;
  width:100% !important;
  padding:16px 0px 16px 20px;
}



.shipment-count{
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
}
.imageOfShipment{
  height: 64px;
   width: 64px;
  border-radius: 8px;
  object-fit:cover;
  //  margin-right: 10px;
}

.shipmentOpen{
  // margin-left: auto;
  // margin-right: 0px;
  // margin top is in doubt 
  // margin-top: 3px; 
  cursor:pointer;
}
.shipmentStatus{
  padding: 3px 8px;
  border-radius: 8px; 
  background: #ECFFF9;
  color: #07956A;
  // margin-right: 0px;
  // margin-top: 19px;
}
  
.shipmentStatus2{
    padding: 3px 8px;
    background: #ECFFF9;
    color: #07956A;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: center;
    max-width:250px;
}

.eat-info{

font-family: Lato;
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-align: left;
color:#05223D;


}

.shipment-p-info{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color:#647788;
  min-width:65px;
}
.shipment-info{
margin-top:12px;
gap:8px;
}

.shipment-images{
display:flex;
width:100%;
overflow-x:scroll;
  gap:8px;
}
  

.shipment-images::-webkit-scrollbar{
display:none;
}

@media screen and (max-width: 767px) {
//   li:has(.shipment2){
//     margin-top:8px;
//   }

// li:has(.shipment2):first-child{
// margin-top:0px;
// }


.status_log_screen{
 margin-bottom:0;
}
.shipmentStatus2 {
 max-width:100px;
}
.social-media-links{
  margin-top:13px;}
}
// css for universal-search
.minHeight110{
 min-height:calc(100vh - 110px);
 }
 .minHeight55{
 min-height:calc(100vh - 55px);
 }
 .minHeight{
 min-height:100vh;
 }
.universal{
  background-color:green;
  min-height:100vh;
    .universal-bg{
        width:100%;
       
        padding:30px 0px;
        display:flex;
        align-items:center;
        justify-content:center;

        .universal-search {
          width:487px;
          padding:64px;
          // position: absolute;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%);
          text-align: center;
          border-radius:8px;
          border:1px solid #e8e3d1;

          .universal-serach-header {
              margin-bottom: 16px;
              .universal-serach-heading{
                font-weight:700;
                font-size:24px;
                line-height:28.8px;
                margin-bottom:23px;
              }
          }
          .header-description{
            margin-bottom:32px;
          }
          .MuiOutlinedInput-root{
            border-radius:8px;
          }
          .universal-searchFields{
            border-radius:8px;
            height:52px;
            margin-bottom:32px;
            width:100%;
            .Mui-focused{
             color:#A1ADB9;
            .MuiInputBase-input{
              color:#05223D;
            }
      }
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
      border-color:#A1ADB9;    
          legend {
               ${ languageDirection == "rtl" ?  ' margin-right: auto' : ''};
               
                // transform: translateX(-100%); 
                  }
     }
          }
          .universal-search-button{
            border-radius:8px;
            height:52px;
            width:100%;
            border:none;
          }
        }
    }
   .universal-footer{
    right: 0;
    // position: absolute;
    bottom: 0;
    margin: auto;
    left: 0;
    }
    .combinationButtons button:last-child{
     border:none !important;
    }
}

  .universal-search .MuiInputLabel-formControl{
  ${ languageDirection == "rtl" ?  'left:28px !important; transform-origin: top left;' : 'left:0px !important'};
}
.universal-no-order-page{
  height:100vh;
  width:100vw;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  padding:0px 16px;
}
@media screen and (max-width: 767px) {
  .universal-search{
    width: 325px;
    padding:32px;
  }
  .universal{
   .social-inner-wrapper {
     width:auto;
    }
  }
  .universal-no-order-page{
    img{
      width:100%;
    } 
  }
}
.universalShipmentList{
  width:40%;
  margin:auto;
}

@media screen and (max-width:767px){
.universalShipmentList{
  width:100%;
 }
  .message-snack-bar .MuiSnackbarContent-root {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .message-snack-bar .MuiSnackbarContent-action {
      margin-left:0;
  }
}


/* ------------------NEW CURBSIDE SCREEN CSS START-------------------------------- */
@media screen and (max-width: 767px) {
  .curb-side-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    background:rgb(238, 238, 238);
    padding: 10px 10px 0px 10px;
    .c-s-order-info{
      background:white;
      border: 1px solid;
      border-radius: 8px;
      .status{
        text-align:center;
        font-size:16px;
        font-weight:bold;
      }
    }
    .c-s-slot-data-card{
      border: 1px solid green;
      border-radius: 8px;
      background: #cfffcf;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 0px;
    }
    .c-s-leaving-time-card{
      padding:10px 0px;
      background:white;
      border:1px solid;
      border-radius:8px;
      text-align:center;
    }
    .share-link-container{
      margin:0;
    }
    .c-s-map-card{
      #top-area{
        margin-top:0;
      }
    }
    .c-s-submit-btn{
      position: sticky;
      bottom:63px;
      height:40px;
      width: 100%;
      z-index: 999;
      display: flex;
      justify-content: center;
      gap: 10px;
    }
    .modal-container{
      background: white;
      width: 60vw;
      padding: 10px;
      p{
        text-align: center;
        padding: 20px;
      }
      .m-c-buttons{
        display:flex;
        justify-content:space-evenly;
      }  
    }
  }

  .curb-side-footer{
    position:sticky;
    bottom:0;
    .social-media-links {
      margin-top: 0px !important;
    }
  }
  .c-s-parking-instruction{
    background: white;
    padding: 20px;
    border-radius: 8px;
    font-family: lato;
    h2{
      text-align:center;
      padding-bottom:6px;
      margin-bottom:4px;
      font-weight:600;
      border-bottom:1px solid #c7c7c7;
      }
      p{
        padding:4px 0px;
        b{
          font-weight:600;
        }
    }
  }
}
		// pre-check-order-confirm-row
			// order-icon-box
			// order-right-content-info
			// order-right-arrow
// curb-side-footer

  .c-s-parking-instruction{
    background: white;
    padding: 20px;
    border-radius: 8px;
    font-family: lato;
    h2{
      text-align:center;
      padding-bottom:6px;
      margin-bottom:4px;
      font-weight:600;
      border-bottom:1px solid #c7c7c7;
      }
      p{
        padding:4px 0px;
        b{
          font-weight:600;
        }
    }
  }

/* ------------------NEW CURBSIDE SCREEN CSS END-------------------------------- */



 `; 




 let modifiedCss = ltr;
 if (languageDirection && languageDirection === 'rtl') {
   modifiedCss = cssjanus.transform(ltr);
 }
 
 
 const GlobalStyles = createGlobalStyle`${modifiedCss}`

 export default GlobalStyles


//  .card-view .slick-slide:nth-child(1) {
//   margin-left: 20px;
// }

// .HeaderInner span.need_help_box button{
//   position:relative !important;
//   top:0 !important;
// }




